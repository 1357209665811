var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.upload($event)}}},[(_vm.files)?_c('div',{staticClass:"mb-12",style:({
      display: 'grid',
      'grid-template-columns': ("repeat(auto-fill, minmax(" + _vm.size + "px, 1fr))"),
      'grid-gap': '2rem 1.5rem',
    })},[_vm._l((_vm.files),function(file,index){return _c('div',{key:file.id},[_c('div',{staticClass:"grey lighten-2 rounded",staticStyle:{"z-index":"1","--aspect-ratio":"237/160"}},[_c('img',{staticClass:"rounded",staticStyle:{"object-fit":"cover"},attrs:{"src":(file && file.url) || '/statics/placeholder.png',"width":"237","height":"160","alt":"Image preview"}}),(!_vm.readonly)?_c('v-btn',{staticStyle:{"position":"absolute","top":"10px","right":"10px","padding":"0","margin":"0"},attrs:{"x-small":"","fab":"","depressed":""},on:{"click":function($event){return _vm.$emit('removeFile', index)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_vm._e(),(_vm.downloadable)?_c('v-btn',{staticStyle:{"position":"absolute","top":"50px","right":"10px","padding":"0","margin":"0"},attrs:{"type":"a","href":file && file.url,"download":"","target":"_blank","x-small":"","fab":"","depressed":""}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()],1),_c('v-text-field',{staticClass:"mt-4",staticStyle:{"width":"100%"},attrs:{"dense":"","outlined":"","hide-details":"","label":"Gib hier deine Wünsche...","name":"name","data-id":file.id,"disabled":(_vm.updatedFiles && _vm.updatedFiles[file.id] === 'updating') ||
            _vm.readonly ||
            true},on:{"change":_vm.updateFilename},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.updatedFiles && _vm.updatedFiles[file.id] === 'updating')?_c('v-progress-circular',{attrs:{"color":"secondary lighten-1","size":"16"}}):(_vm.updatedFiles && _vm.updatedFiles[file.id] === 'success')?_c('p',{staticClass:"mb-2 mr-1 text-caption text-uppercase success--text font-weight-medium",staticStyle:{"align-self":"flex-end"}},[_vm._v(" saved ")]):_vm._e()]},proxy:true}],null,true),model:{value:(file.name),callback:function ($$v) {_vm.$set(file, "name", $$v)},expression:"file.name"}})],1)}),_c('div',{class:_vm.btnClass,staticStyle:{"min-height":"170px","border":"1px dashed rgba(166, 155, 146, 0.6)","border-radius":"4px","display":"grid","place-content":"center"},style:({ 'grid-column': _vm.files.length === 0 ? '1 / -1' : 'unset' })},[(
          !_vm.readonly &&
            (_vm.multiple ||
              (_vm.multiple === false &&
                (_vm.files === void 0 || (_vm.files && _vm.files.length === 0))))
        )?_c('button',{staticClass:"d-flex align-center secondary--text",staticStyle:{"max-width":"12rem","padding":"12px 16px","border":"1px solid rgba(166, 155, 146, 0.6)","border-radius":"4px","text-align":"left"},attrs:{"disabled":_vm.multiple === false && _vm.files && _vm.files.length > 0},on:{"click":function($event){return _vm.$refs.inputFile.click()}}},[(_vm.icon)?_c('v-icon',{attrs:{"left":"","color":"secondary"}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.uploading ? ("Uploading... (" + _vm.counter + ")") : _vm.uploadLabel)+" ")],1):_vm._e()])],2):_vm._t("default"),_c('input',{ref:"inputFile",staticClass:"d-none",attrs:{"type":"file","multiple":_vm.multiple,"accept":_vm.accept},on:{"input":_vm.upload}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }