import Vue from 'vue'
import VueRouter from 'vue-router'
import Shell from '@/views/shell.vue'
import Home from '@/views/home.vue'
import Wizard from '@/views/wizard/wizard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Shell,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
      },
      {
        path: 'wizard',
        name: 'wizard',
        component: Wizard,
      },
      {
        path: 'thankyou',
        name: 'thankyou',
        component: () => import('@/views/wizard/thankyou.vue'),
      },
      {
        path: 'admin',
        name: 'admin',
        component: () => import('@/views/records/records.vue'),
      },
      {
        path: 'admin/:email',
        name: 'record',
        component: () => import('@/views/records/record.vue'),
      },
      {
        path: 'boxes/:id',
        name: 'box',
        component: () => import('@/views/boxes/box.vue'),
      },
    ],
  },
  // {
  // path: '/about',
  // name: 'About',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () =>
  // import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
