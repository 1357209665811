<script>
export default {
  props: ['steps', 'current'],
}
</script>
<template>
  <div
    class=""
    style="
      display: grid;
      grid-template-columns: repeat(auto-fit, 300px);
      justify-content: center;
      grid-gap: 1.5rem;
    "
  >
    <div
      v-for="(step, index) in steps"
      :key="step.title"
      class="pa-5 rounded-lg"
      :style="{
        border:
          current === index || step.done
            ? `${step.done ? '2' : '1'}px solid ${
                step.done ? '#6FCF97' : '#A69B92'
              }`
            : 'unset',
      }"
    >
      <div>
        <p
          :class="[
            'mb-3 d-inline-flex justify-center align-center rounded-circle',
            current === index || step.done
              ? 'font-weight-bold'
              : 'font-weight-light',
            { primary: current === index || step.done },
            { 'white--text': current === index || step.done },
          ]"
          style="width: 32px; height: 32px;"
          :style="{
            border: step.done ? '2px solid #6FCF97 !important' : 'unset',
          }"
        >
          {{ index + 1 }}
        </p>
        <v-icon v-if="step.done" color="#6FCF97" class="mt-1 float-right">
          mdi-check
        </v-icon>
        <p class="font-weight-bold">
          {{ step.title }}
        </p>
      </div>

      <div class="font-weight-light">
        {{ step.text }}
      </div>
    </div>
  </div>
</template>
