import Vue from 'vue'

export const state = Vue.observable({
  data: {},
  isAuth: false,
  token: null,
})

export const initAuth = () => {
  const data = JSON.parse(localStorage.getItem('auth') || '{}')
  state.data = data
  state.isAuth = data.token != void 0
  state.token = data.token
}

export const setAuth = data => {
  localStorage.setItem('auth', JSON.stringify(data))

  state.data = data
  state.isAuth = data.token != void 0
  state.token = data.token
}

export const removeAuth = () => {
  localStorage.removeItem('auth')

  state.data = {}
  state.isAuth = false
  state.token = null
}
