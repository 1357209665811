<script>
export default {
  data() {
    return {
      drawer: false,
      links: Object.freeze([
        [true, 'mdi-file-chart-outline', 'KitchX One', { name: 'home' }],
        [
          false,
          'mdi-file-chart-outline',
          'Qualitätsversprechen',
          { name: 'xd' },
        ],
        [false, 'mdi-file-chart-outline', 'Unsen Konzept', { name: 'xd' }],
        [false, 'mdi-file-chart-outline', 'Digitaler Showroom', { name: 'xd' }],
        [false, 'mdi-file-chart-outline', 'Info', { name: 'xd' }],
        [false, 'mdi-file-chart-outline', 'Kontakt', { name: 'xd' }],
        [
          false,
          'mdi-file-chart-outline',
          'KitchX konfigurieren',
          { name: 'xd' },
        ],
      ]),
    }
  },
}
</script>
<template>
  <v-app>
    <!-- <v-navigation-drawer v-model="drawer" app right color="primary">
      <v-sheet color="grey lighten-4" class="px-4 py-6">
        <div class="font-weight-light text-h5">KitchX</div>
        <p class="mb-0 text-body-2 grey--text text--darken-1">
          Lorem, ipsum...
        </p>
      </v-sheet>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-for="[active, icon, text, to] in links"
          :key="text"
          :to="to"
          link
          exact-path
          dark
          exact-active-class="white--text"
          :disabled="!active"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->

    <v-app-bar app flat dark class="px-4 px-md-12 fix-toolbar">
      <div class="container d-flex align-center justify-space-between">
        <div style="min-width: 48px;"></div>

        <router-link
          :to="{ name: 'home' }"
          class="font-weight-light text-h5 white--text no-hover"
        >
          <img
            src="/icons/logo-black.jpg"
            width="768"
            height="210"
            alt="Kitchx logo"
            class="d-block"
            style="height: auto; max-width: 120px;"
          />
        </router-link>

        <!-- <div class="hidden-md-and-down justify-end" style="display: flex;">
          <div
            v-for="[active, icon, text] in links"
            :key="text"
            class="ml-8 text-subtitle-2"
          >
            {{ text }}
          </div>
        </div> -->

        <div class="hidden-sm-and-down" style="min-width: 48px;"></div>

        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="hidden-md-and-up"
          style="visibility: hidden;"
        />
      </div>
    </v-app-bar>

    <v-main class="grey--text text--darken-4">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>

    <v-footer padless class="white d-block">
      <!-- <div class="primary px-4 px-md-12">
        <div
          class="container py-6 row no-gutters align-center white--text text-subtitle-2 font-weight-bold"
        >
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            info@kitchx.de
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            +49 156 7839 4775
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            +49 156 7839 4775
          </div>
          <div class="col-12 col-md-6">
            Du erreichst uns Montag bis Freitag von 10.00 bis 19.00 Uhr und
            Samstag von 11.00 bis 19.00 Uhr
          </div>
        </div>
      </div> -->

      <!-- <div class="px-4 px-md-12">
        <div class="container py-6 row no-gutters text-subtitle-2">
          <div class="col-12 col-md-2">
            <div class="mb-2 font-weight-bold text-raleway">Unsere Produkt</div>
            <router-link to="#" class="d-block no-hover">
              Kitch X One
            </router-link>
          </div>
          <div class="col-12 col-md-2 mt-6 mt-md-0">
            <div class="mb-2 font-weight-bold text-raleway">Über uns</div>
            <router-link to="#" class="d-block no-hover">
              KitchX Qualität
            </router-link>
            <router-link to="#" class="d-block no-hover">
              Über uns
            </router-link>
            <router-link to="#" class="d-block no-hover">
              Karriere
            </router-link>
            <router-link to="#" class="d-block no-hover">
              Unser Konzept
            </router-link>
          </div>
          <div class="col-12 col-md-2 mt-6 mt-md-0">
            <div class="mb-2 font-weight-bold text-raleway">Hilfe</div>
            <router-link to="#" class="d-block no-hover">
              FAQ
            </router-link>
            <router-link to="#" class="d-block no-hover">
              Versand und Montage
            </router-link>
          </div>
          <div class="col-12 col-md-3 mt-6 mt-md-0">
            <div class="mb-2 font-weight-bold text-raleway">
              Akzeptierte Zahlungsmethoden
            </div>
            <router-link to="#" class="d-block no-hover">
              Vorkasse
            </router-link>
            <router-link to="#" class="d-block no-hover">
              Paypal
            </router-link>
          </div>
          <div class="col-12 col-md-3 mt-6 mt-md-0 text-md-right">
            <div class="mb-2 font-weight-bold text-raleway">
              Von vielen geschätzt
            </div>
            <div to="#" class="d-block no-hover">
              Lesen Sie unsere 11 Bewertungen auf. Trustpilot
            </div>
            <router-link to="#" class="d-block no-hover">
              Facebook
            </router-link>
          </div>
        </div>
      </div> -->

      <div class="px-4 px-md-12" style="border-top: 1px solid #A69B92;">
        <div class="container py-6 row no-gutters text-subtitle-2 align-center">
          <div class="col-12 col-md-2">
            <router-link
              :to="{ name: 'home' }"
              class="font-weight-light text-h5 white--text no-hover"
            >
              <img
                src="/icons/logo.webp"
                width="768"
                height="210"
                alt="Kitchx logo"
                class="d-block"
                style="height: auto; max-width: 120px;"
              />
            </router-link>
          </div>
          <div class="col-12 col-md-4 mt-6 mt-md-0">
            Copyright {{ new Date().getFullYear() }} KitchX UG. Alle Rechte
            vorbehalten
          </div>
          <div class="col-12 col-md-3 mt-6 mt-md-0 d-flex">
            <div class="flex-1 text-md-center">
              <a href="https://www.kitchx.de/impressum" class="no-hover">
                Impressum
              </a>
            </div>
            <div class="flex-1 text-md-center">
              <a href="https://www.kitchx.de/agb" class="no-hover">
                AGBs
              </a>
            </div>
          </div>
          <div class="col-12 col-md-3 mt-6 mt-md-0 d-flex">
            <div class="flex-1 text-md-center">
              <a href="https://www.kitchx.de/datenschutz" class="no-hover">
                Datenschutzerklarung
              </a>
            </div>
            <div class="flex-1 text-md-center">
              <a
                href="https://www.kitchx.de/widerrufsbelehrung"
                class="no-hover"
              >
                Widerrufsbelehrung
              </a>
            </div>
          </div>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>
<style>
.fix-toolbar .v-toolbar__content {
  padding-left: 0;
  padding-right: 0;
}
</style>
