import { isObject, identity } from './utils.js'
import { replaceParams, makeFormData } from './http.js'

const bypass = Symbol('bypass')

const delay = ms => value =>
  new Promise(resolve => setTimeout(resolve, ms, value))

export const createApi = (
  endpoints,
  defaultActions = {},
  { API = '', unwrapData, validCodes, throttle } = {}
) =>
  function api(endpointName, actionName, options = {}) {
    const endpoint = endpoints[endpointName] || {}
    const action = defaultActions[actionName] || {}
    const url = replaceParams(
      options.url || action.url || (actionName && `/${actionName}`) || '',
      options
    )
    const transform = options.transform || (() => res => Promise.resolve(res))

    if (
      'data' in action &&
      (action.dataIf == void 0 || action.dataIf(url) === true)
    ) {
      const computedData = action.data({
        api,
        endpointName,
        endpoint,
        actionName,
        options,
        bypass,
      })

      if (computedData !== bypass) return Promise.resolve(computedData)
    }

    const headers = {
      Accept: 'application/json, text/plain, */*',
      ...(options.token != void 0
        ? { Authorization: `Bearer ${options.token}` }
        : {}),
      ...(options.headers || {}),
    }
    let method = options.method || action.method || 'GET'
    let body = options.data
    let query =
      options.query == void 0 || Object.keys(options.query).length === 0
        ? ''
        : '?' + new URLSearchParams(options.query).toString()

    if (body != void 0 && isObject(body)) {
      if (
        options.formData === true ||
        Object.entries(body).some(([, value]) => value instanceof File)
      ) {
        body = makeFormData(body)
      } else {
        headers['Content-Type'] = 'application/json'
        body = JSON.stringify(body)
      }
    }

    const time = Date.now()
    let delta,
      minTime =
        throttle && throttle[Math.floor(Math.random() * throttle.length)]

    return fetch(`${API}/${endpointName}${url}${query}`, {
      method,
      headers,
      body,
    })
      .then(response =>
        validCodes == void 0 || validCodes.includes(response.status)
          ? response
          : Promise.reject(response)
      )
      .then(response =>
        (response.headers.get('Content-Type') || []).includes(
          'application/json'
        )
          ? response
              .json()
              .then(responseData =>
                options.unwrapData !== false && unwrapData != void 0
                  ? responseData[options.unwrapData || unwrapData] ||
                    responseData
                  : responseData
              )
          : response
      )
      .then(transform(api))
      .then(
        ((delta = Date.now() - time),
        throttle == void 0 || delta > minTime
          ? identity
          : delay(minTime - delta))
      )
      .catch(responseOrError =>
        responseOrError instanceof Error
          ? responseOrError
          : (responseOrError.headers.get('Content-Type') || []).includes(
              'application/json'
            )
          ? responseOrError
              .json(({ message }) => Error(message))
              .catch(error => error)
          : Error(responseOrError.statusText)
      )
  }
