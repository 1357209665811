<script>
import Types from '@/common/types.js'
import XInputCheckbox from '@/components/x-input-checkbox.vue'

export default {
  data() {
    return {
      step: 0,
      form: {},
      visited: {},
      VUE_APP_EDITOR: process.env.VUE_APP_EDITOR,
      toolconfig: {},
      host: window.location.origin,
      Types,
    }
  },
  created() {
    this.next((Number(this.$route.query.editor) || 1) - 1)
  },
  mounted() {
    window.addEventListener('message', this.onmessage)
  },
  methods: {
    next(goto) {
      this.step = goto != void 0 && !isNaN(goto) ? Number(goto) : this.step + 1

      // reset state

      // conditional steps
      if (this.step + 1 === 10 && this.form['Küchenformen'] != void 0) {
        this.step += goto == void 0 ? 1 : -1
      }

      // modals

      this.$set(this.visited, this.step + 1, true)

      window.scrollTo(0, 0)
    },
    cancel() {
      this.next(16)
    },
    onmessage({ origin, data }) {
      if (origin !== this.VUE_APP_EDITOR) return

      this.$set(this.form, 'editor', data)
      console.log('saving client...', data)
    },
    save() {
      this.$emit('input', this.form)
      this.$emit('finish')
    },
  },
  components: {
    XInputCheckbox,
  },
}
</script>
<template>
  <v-tabs-items v-model="step">
    <v-tab-item>
      <h5 class="mb-3 text-h6 font-weight-bold text-raleway text-center">
        Grundrissdaten jetzt eingeben
      </h5>
      <p
        class="mx-auto mb-8 font-weight-light text-center"
        style="max-width: 644px;"
      >
        Damit wir Dir ein Angebot machen können, benötigen wir von dir Angaben
        zu deinem Küchenraum und Maße
      </p>

      <v-btn
        color="secondary"
        depressed
        class="my-16 mx-auto d-block"
        @click="next()"
      >
        Grundrissdaten jetzt eingeben
      </v-btn>

      <div class="mt-8 text-center">
        <v-btn color="grey darken-1" outlined depressed @click="$emit('back')">
          zurück
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="$emit('finish')"
          class="ml-md-4 mt-4 mt-md-0"
        >
          Daten später eingeben
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-tab-item>

    <v-tab-item>
      <v-btn color="grey darken-1" outlined depressed @click="cancel">
        <v-icon left>mdi-chevron-left</v-icon>
        Daten später eingeben
      </v-btn>

      <h5 class="mb-3 text-h6 font-weight-bold text-raleway text-center">
        Wähle Deine Raumform
      </h5>

      <iframe
        :src="`${VUE_APP_EDITOR}/?panel=0`"
        frameborder="0"
        style="width: 100%; min-height: 560px;"
      ></iframe>

      <div class="mt-8 text-center">
        <v-btn color="grey darken-1" outlined depressed @click="next(step - 1)">
          zurück
        </v-btn>
        <v-btn color="primary" depressed @click="next()" class="ml-4">
          Weiter
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-tab-item>

    <v-tab-item>
      <v-btn color="grey darken-1" outlined depressed @click="cancel">
        <v-icon left>mdi-chevron-left</v-icon>
        Daten später eingeben
      </v-btn>

      <h5 class="mb-3 text-h6 font-weight-bold text-raleway text-center">
        Fenster
      </h5>

      <p
        class="mx-auto mb-8 font-weight-light text-center"
        style="max-width: 644px;"
      >
        Bitte gib uns unten die Fensterbreite und den Abstand zwischen Fußboden
        und Fensterbrett Oberkante an.
      </p>

      <div
        style="
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        max-width: 800px;
      "
        class="mx-auto"
      >
        <div class="d-flex flex-column align-end justify-center">
          <p class="mb-1" style="min-width: 180px;">Breite des Fensters</p>
          <v-text-field
            type="number"
            min="0"
            dense
            outlined
            required
            hide-details
            v-model="toolconfig.breite"
            label="Breite in cm"
            name="Breite in cm"
            placeholder="Breite in cm"
            class=""
            style="max-width: 180px; display: inline-flex; flex: unset;"
          ></v-text-field>
        </div>
        <div class="d-flex flex-column align-center">
          <img
            src="/statics/wizard/12-fenster.png"
            alt=""
            class="img-fluid mb-2"
          />
          <p class="mb-1" style="min-width: 180px;">Abstand zum Boden</p>
          <v-text-field
            type="number"
            min="0"
            dense
            outlined
            required
            v-model="toolconfig.abstand"
            label="Abstand in cm"
            name="Abstand in cm"
            placeholder="Abstand in cm"
            class=""
            style="max-width: 180px; display: inline-flex; flex: unset;"
          ></v-text-field>
        </div>
        <div>
          Wo befindest sich der Griff deines Fenster?
          <x-input-checkbox
            dense
            grid-gap=".5rem"
            :options="[
              {
                label: 'LINKS',
                value: 'Links',
              },
              {
                label: 'MITTE',
                value: 'Mitte',
              },
              {
                label: 'RECHTS',
                value: 'Rechts',
              },
            ]"
            v-model="toolconfig.wo"
            class="mt-3 mb-6"
          />
          <img
            src="/statics/wizard/12-fenster-2.png"
            alt=""
            class="pb-4 img-fluid"
          />
        </div>
      </div>

      <div class="mt-8 text-center">
        <v-btn
          color="grey darken-1"
          outlined
          depressed
          @click="
            next(step + 2)
            toolconfig = {}
          "
        >
          Der Raum besitzt kein Fenster
        </v-btn>
        <v-btn color="primary" depressed @click="next()" class="ml-4">
          Jetzt Position eintragen
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-tab-item>

    <v-tab-item>
      <v-btn color="grey darken-1" outlined depressed @click="cancel">
        <v-icon left>mdi-chevron-left</v-icon>
        Daten später eingeben
      </v-btn>

      <iframe
        :src="
          `${VUE_APP_EDITOR}/?recovery=1&panel=0&noresize=1&size=${toolconfig.breite}&action=window`
        "
        frameborder="0"
        style="width: 100%; min-height: 560px;"
        class="mt-3"
      ></iframe>

      <div class="mt-8 text-center">
        <v-btn
          color="grey darken-1"
          outlined
          depressed
          @click="
            next(step - 1)
            toolconfig = {}
          "
        >
          Weiteres Fenster platzieren
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="
            next()
            toolconfig = {}
          "
          class="ml-4"
        >
          Jetzt Türe platzieren
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-tab-item>

    <v-tab-item>
      <v-btn color="grey darken-1" outlined depressed @click="cancel">
        <v-icon left>mdi-chevron-left</v-icon>
        Daten später eingeben
      </v-btn>

      <h5 class="mb-3 text-h6 font-weight-bold text-raleway text-center">
        Tür
      </h5>

      <p
        class="mx-auto mb-8 font-weight-light text-center"
        style="max-width: 644px;"
      >
        Bitte gib uns die Türenbreite an. Bitte wie zu sehen inklusive
        Türrahmen.
      </p>

      <div
        style="
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        max-width: 800px;
      "
        class="mx-auto"
      >
        <div class="d-flex flex-column align-end justify-center"></div>
        <div class="d-flex flex-column align-center">
          <img src="/statics/wizard/12-tur.png" alt="" class="img-fluid mb-2" />
          <p class="mb-1" style="min-width: 180px;">Breite</p>
          <v-text-field
            type="number"
            min="0"
            dense
            outlined
            required
            v-model="toolconfig.breite"
            label="Breite in cm"
            name="Breite in cm"
            placeholder="Breite in cm"
            class=""
            style="max-width: 180px; display: inline-flex; flex: unset;"
          ></v-text-field>
        </div>
        <div>
          Wo befindet sich der Griff der Tür, wenn Du im Küchenraum vor der Tür
          stehst?
          <x-input-checkbox
            dense
            grid-gap=".5rem"
            :options="[
              {
                label: 'LINKS',
                value: 'Links',
              },
              {
                label: 'RECHTS',
                value: 'Rechts',
              },
              {
                label: 'ES IST EIN DURCHBRUCH OHNE TÜR',
                value: 'Es ist ein Durchbruch ohne Tür',
              },
            ]"
            v-model="toolconfig.wo"
            class="mt-3 mb-6"
          />
        </div>
      </div>

      <div class="mt-8 text-center">
        <v-btn
          color="grey darken-1"
          outlined
          depressed
          @click="
            next(step - 2)
            toolconfig = {}
          "
        >
          zurück
        </v-btn>
        <v-btn color="primary" depressed @click="next()" class="ml-4">
          Jetzt Türposition eintragen
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-tab-item>

    <v-tab-item>
      <v-btn color="grey darken-1" outlined depressed @click="cancel">
        <v-icon left>mdi-chevron-left</v-icon>
        Daten später eingeben
      </v-btn>

      <iframe
        :src="
          `${VUE_APP_EDITOR}/?recovery=1&panel=0&noresize=1&size=${toolconfig.breite}&action=door`
        "
        frameborder="0"
        style="width: 100%; min-height: 560px;"
        class="mt-3"
      ></iframe>

      <div class="mt-8 text-center">
        <v-btn
          color="grey darken-1"
          outlined
          depressed
          @click="
            next(step - 1)
            toolconfig = {}
          "
        >
          Jetzt weitere Tür platzieren
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="
            next()
            toolconfig = {}
          "
          class="ml-4"
        >
          Jetzt Wasseranschluss angeben
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-tab-item>

    <v-tab-item>
      <v-btn color="grey darken-1" outlined depressed @click="cancel">
        <v-icon left>mdi-chevron-left</v-icon>
        Daten später eingeben
      </v-btn>

      <iframe
        :src="`${VUE_APP_EDITOR}/?recovery=1&panel=0&noresize=1&action=water`"
        frameborder="0"
        style="width: 100%; min-height: 560px;"
        class="mt-3"
      ></iframe>

      <img
        src="/statics/wizard/12-water-2.svg"
        width="285"
        alt=""
        class="img-fluid d-block mx-auto mt-3"
      />

      <div class="mt-8 text-center">
        <v-btn color="grey darken-1" outlined depressed @click="next(step - 2)">
          zurück
        </v-btn>
        <v-btn color="primary" depressed @click="next()" class="ml-4">
          weiter
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-tab-item>

    <v-tab-item>
      <v-btn color="grey darken-1" outlined depressed @click="cancel">
        <v-icon left>mdi-chevron-left</v-icon>
        Daten später eingeben
      </v-btn>

      <h5 class="mb-3 text-h6 font-weight-bold text-raleway text-center">
        Welchen Wasseranschluss hast du in deiner Wohnung?
      </h5>

      <p
        class="mx-auto mb-6 font-weight-light text-center"
        style="max-width: 744px;"
      >
        Es gibt in Deutschland zwei Arten von Wasseranschlüssen. Es ist ganz
        einfach zu erkennen, welchen du hast. Schau dir die Bilder unten an.
        Hast du zwei oder drei Anschlüsse an der Wand? Wenn eine Küche vorhanden
        ist, findest du die Wasseranschlüsse unter Deinem Waschbecken.
      </p>

      <x-input-checkbox
        :options="[
          {
            caption: '1 Wasseranschluss und 1 Abwasser',
            value: '1 Wasseranschluss und 1 Abwasser',
            url: '/statics/wizard/12-water-4.png',
          },
          {
            caption: '2 Wasseranschlüsse und 1 Abwasser',
            value: '2 Wasseranschlüsse und 1 Abwasser',
            url: '/statics/wizard/12-water-3.png',
          },
        ]"
        :value="toolconfig.waterType"
        @input="
          $set(toolconfig, 'waterType', $event)
          next(step + ($event === '1 Wasseranschluss und 1 Abwasser' ? 1 : 2))
        "
      />

      <div class="mt-8 text-center">
        <v-btn color="grey darken-1" outlined depressed @click="next(step - 1)">
          zurück
        </v-btn>
      </div>
    </v-tab-item>

    <v-tab-item>
      <v-btn color="grey darken-1" outlined depressed @click="cancel">
        <v-icon left>mdi-chevron-left</v-icon>
        Daten später eingeben
      </v-btn>

      <h5 class="mb-3 text-h6 font-weight-bold text-raleway text-center">
        Boiler bzw Durchlauferhitzer
      </h5>

      <p
        class="mx-auto mb-6 font-weight-light text-center"
        style="max-width: 644px;"
      >
        Bei Deiner Art des Wasseranschlusses benötigen wir für Warmwasser einen
        sogenannten Boiler oder einen Durchlauferhitzer. Schau Dir hierzu die
        Bilder an. Besitzt du bereits eins der auf den Bildern zu sehenden
        Geräten?
      </p>

      <img
        src="/statics/wizard/12-boiler.png"
        width="284"
        height="148"
        alt="Boiler preview"
        class="mx-auto d-block img-fluid"
      />

      <div class="mt-8 text-center">
        <v-btn
          color="grey darken-1"
          outlined
          depressed
          @click="
            $set(form, 'boiler', 'Ich habe bereits einen Boiler')
            next()
          "
        >
          Ich habe bereits einen Boiler
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="
            $set(form, 'boiler', 'Ich möchte einen Boiler bei kitchX kaufen')
            next()
          "
          class="ml-4"
        >
          Ich möchte einen Boiler bei kitchX kaufen
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-tab-item>

    <v-tab-item>
      <v-btn color="grey darken-1" outlined depressed @click="cancel">
        <v-icon left>mdi-chevron-left</v-icon>
        Daten später eingeben
      </v-btn>

      <div
        style="
          max-width: 900px;
        "
        class="mx-auto mt-8"
      >
        <div style="position: relative;">
          <img src="/statics/wizard/12-water-5.png" alt="" class="img-fluid" />
          <div style="position: absolute; top: 100px; left: 50px;">
            <p class="mb-1" style="min-width: 180px;">Abstand</p>
            <v-text-field
              type="number"
              min="0"
              dense
              outlined
              required
              v-model="toolconfig.abstand1"
              label="Abstand in cm"
              name="Abstand in cm 1"
              placeholder="Abstand in cm"
              class=""
              style="max-width: 160px; display: inline-flex; flex: unset;"
            ></v-text-field>
          </div>

          <div style="position: absolute; top: 30px; left: 200px;">
            <p class="mb-1" style="min-width: 180px;">Abstand</p>
            <v-text-field
              type="number"
              min="0"
              dense
              outlined
              required
              v-model="toolconfig.abstand2"
              label="Abstand in cm"
              name="Abstand in cm 2"
              placeholder="Abstand in cm"
              class=""
              style="max-width: 160px; display: inline-flex; flex: unset;"
            ></v-text-field>
          </div>

          <div style="position: absolute; top: 100px; left: 360px;">
            <p class="mb-1" style="min-width: 180px;">Abstand</p>
            <v-text-field
              type="number"
              min="0"
              dense
              outlined
              required
              v-model="toolconfig.abstand3"
              label="Abstand in cm"
              name="Abstand in cm 3"
              placeholder="Abstand in cm"
              class=""
              style="max-width: 160px; display: inline-flex; flex: unset;"
            ></v-text-field>
          </div>
        </div>
      </div>

      <div class="mt-8 text-center">
        <v-btn color="grey darken-1" outlined depressed @click="next(step - 2)">
          zurück
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="
            next()
            toolconfig = {}
          "
          class="ml-4"
        >
          weiter
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-tab-item>

    <v-tab-item>
      <v-btn color="grey darken-1" outlined depressed @click="cancel">
        <v-icon left>mdi-chevron-left</v-icon>
        Daten später eingeben
      </v-btn>

      <h5 class="mb-3 text-h6 font-weight-bold text-raleway text-center">
        Starkstromanschluss
      </h5>

      <p
        class="mx-auto mb-3 font-weight-light text-center"
        style="max-width: 600px;"
      >
        Ofen und Herd wird in der Regel an einen Starkstromanschluss
        angeschlossen. Unten siehst du Bilder dieses Anschlusses. Falls dieser
        nicht vorhanden ist, wähle bitte unten “Nicht vorhanden”
      </p>

      <img
        src="/statics/wizard/12-light.png"
        alt=""
        class="mx-auto d-block img-fluid"
      />

      <div class="mt-8 text-center">
        <v-btn color="grey darken-1" outlined depressed @click="next(step + 2)">
          Kein Anschluss vorhanden
        </v-btn>
        <v-btn color="primary" depressed @click="next()" class="ml-4">
          Jetzt Strom angeben
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-tab-item>

    <v-tab-item>
      <v-btn color="grey darken-1" outlined depressed @click="cancel">
        <v-icon left>mdi-chevron-left</v-icon>
        Daten später eingeben
      </v-btn>

      <iframe
        :src="`${VUE_APP_EDITOR}/?recovery=1&panel=0&noresize=1&action=light`"
        frameborder="0"
        style="width: 100%; min-height: 560px;"
        class="mt-3"
      ></iframe>

      <div class="mt-8 text-center">
        <v-btn color="grey darken-1" outlined depressed @click="next(step - 1)">
          zurück
        </v-btn>
        <v-btn color="primary" depressed @click="next()" class="ml-4">
          weiter
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-tab-item>

    <v-tab-item>
      <v-btn color="grey darken-1" outlined depressed @click="cancel">
        <v-icon left>mdi-chevron-left</v-icon>
        Daten später eingeben
      </v-btn>

      <h5 class="mb-3 text-h6 font-weight-bold text-raleway text-center">
        Stromanschluss für Dunstabzug
      </h5>

      <p
        class="mx-auto mb-3 font-weight-light text-center"
        style="max-width: 644px;"
      >
        Für die Dunstabzugshaube benötigen wir einen Stromanschluss, der sich
        weiter oben an der Wand befindet. Schau dir hierzu gerne die Bilder an.
        Falls du mehr als einen hast, gib uns gerne alle an.
      </p>

      <img
        src="/statics/wizard/12-air.png"
        alt=""
        class="mx-auto d-block img-fluid"
      />

      <div class="mt-8 text-center">
        <v-btn color="grey darken-1" outlined depressed @click="next(step + 2)">
          Kein Anschluss vorhanden
        </v-btn>
        <v-btn color="primary" depressed @click="next()" class="ml-4">
          Jetzt Stromanschluss angeben
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-tab-item>

    <v-tab-item>
      <v-btn color="grey darken-1" outlined depressed @click="cancel">
        <v-icon left>mdi-chevron-left</v-icon>
        Daten später eingeben
      </v-btn>

      <iframe
        :src="`${VUE_APP_EDITOR}/?recovery=1&panel=0&noresize=1&action=air`"
        frameborder="0"
        style="width: 100%; min-height: 560px;"
        class="mt-3"
      ></iframe>

      <div class="mt-8 text-center">
        <v-btn color="grey darken-1" outlined depressed @click="next(step - 1)">
          zurück
        </v-btn>
        <v-btn color="primary" depressed @click="next()" class="ml-4">
          weiter
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-tab-item>

    <v-tab-item>
      <v-btn color="grey darken-1" outlined depressed @click="cancel">
        <v-icon left>mdi-chevron-left</v-icon>
        Daten später eingeben
      </v-btn>

      <h5 class="mb-8 text-h6 font-weight-bold text-raleway text-center">
        Wähle die Küchenformen aus, die dir gefallen
      </h5>

      <div class="d-flex align-center">
        <!-- <div class="pa-3 rounded" style="border: 1px solid #dadada;">
          <img
            src="/statics/wizard/12-shapes-manual.png"
            width="124"
            height="115"
            alt="Preview"
            class="mb-3 mx-auto d-block img-fluid"
          />
          <v-btn
            color="grey darken-1"
            outlined
            depressed
            small
            @click="
              $delete(form, 'Küchenformen')
              next()
            "
            style="width: 100%;"
          >
            Raumform bearbeiten
          </v-btn>
        </div> -->
        <div class="flex-1">
          <x-input-checkbox
            dense
            :options="Types.Küchenformen"
            :value="form['Küchenformen']"
            @input="
              $set(form, 'Küchenformen', $event)
              save()
            "
          />
        </div>
      </div>

      <div class="mt-8 text-center">
        <v-btn color="grey darken-1" outlined depressed @click="next(step - 1)">
          zurück
        </v-btn>
      </div>
    </v-tab-item>

    <!-- Kitchen editor -->

    <v-tab-item>
      <v-btn color="grey darken-1" outlined depressed @click="cancel">
        <v-icon left>mdi-chevron-left</v-icon>
        Daten später eingeben
      </v-btn>

      <h5 class="mb-8 text-h6 font-weight-bold text-raleway text-center">
        Deine Raumform
      </h5>

      <iframe
        :src="
          `${VUE_APP_EDITOR}/?recovery=1&panel=0&noresize=1&controls=kitchen`
        "
        frameborder="0"
        style="width: 100%; min-height: 560px;"
      ></iframe>

      <div class="mt-8 text-center">
        <v-btn color="grey darken-1" outlined depressed @click="next(step - 1)">
          zurück
        </v-btn>
        <v-btn color="primary" depressed @click="next()" class="ml-4">
          weiter
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-tab-item>

    <v-tab-item>
      <div class="text-center mb-6">
        <v-icon dense>mdi-information-outline</v-icon>
      </div>

      <h5 class="mb-6 text-h6 font-weight-bold text-raleway text-center">
        Willst du die Eingabe der Raummaße wirklich beenden?
      </h5>
      <p
        class="mx-auto mb-8 font-weight-light text-center"
        style="max-width: 644px;"
      >
        Deine Raummaße werden von uns benötigt um Dir ein verlässliches Angebot
        zukommen zu lassen. Ohne Maße geht das leider nicht. <br /><br />
        Du möchtest die Eingabe trotzdem beenden? Kein Problem. WIr schicken Dir
        einen Link und Du kannst da weiter machen, wo Du aufgehört hast.
      </p>

      <div class="mt-8 text-center">
        <v-btn
          color="grey darken-1"
          outlined
          depressed
          @click="$emit('cancel')"
        >
          Raumskitze beenden
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="next(1)"
          class="ml-md-4 mt-4 mt-md-0"
          style="text-transform: unset;"
        >
          RAUMMAßE
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-tab-item>
  </v-tabs-items>
</template>
