import {
  of,
  merge,
  NEVER,
  noop,
  // pipe,
  defer,
  timer,
  // concat,
  // combineLatest,
  // asapScheduler,
} from 'rxjs'
import {
  share,
  mapTo,
  filter,
  // delay,
  // tap,
  switchMap,
  concatAll,
  concatMap,
  map,
  scan,
  withLatestFrom,
  startWith,
  mergeMap,
  // repeatWhen,
  // exhaustMap,
  tap,
  // debounceTime,
  // pluck,
} from 'rxjs/operators'
import { notNull } from '@/common/utils.js'

export function makeFileUploaderStream({
  defaultFiles$ = NEVER,
  uploadAction$,
  removeFileAction$ = NEVER,
  updateFilenameAction$ = NEVER,
  create,
  update,
  // fetch$,
  // reset$ = NEVER,
  // fetch,
  // successDelay = 3200,
  afterSuccess = noop,
  // afterSuccessDelay = noop,
  // unwrapData = 'data',
}) {
  uploadAction$ = uploadAction$.pipe(
    filter(files => 0 in files),
    share()
  )

  const uploadedFile$ = uploadAction$.pipe(
    concatAll(),
    concatMap(file => defer(() => create(file))),
    tap(afterSuccess),
    share()
  )

  const uploaderQueue$ = merge(
    uploadAction$.pipe(map(files => acc => acc.concat(files))),
    uploadedFile$.pipe(map(() => acc => acc.slice(1)))
  ).pipe(
    scan((acc, action) => action(acc), []),
    share()
  )

  const uploading$ = uploaderQueue$.pipe(map(queue => 0 in queue))

  const counter$ = uploadAction$.pipe(
    withLatestFrom(uploaderQueue$),
    switchMap(([, queue]) =>
      uploadedFile$.pipe(
        scan(acc => acc + 1, 0),
        startWith(0),
        map(counter => `${counter}/${queue.length}`)
      )
    )
  )

  const updatedFile$ = updateFilenameAction$.pipe(
    mergeMap(({ fileId, name }) => defer(() => update({ fileId, name })))
  )

  const files$ = merge(
    uploadedFile$.pipe(
      filter(notNull),
      map(fileData => acc => acc.concat(fileData))
    ),
    defaultFiles$.pipe(
      filter(notNull),
      map(files => () => files)
    ),
    // TODO: add updatedFile$
    removeFileAction$.pipe(
      map(fileIndex => acc => acc.filter((_, index) => index !== fileIndex))
    )
  ).pipe(
    scan((acc, action) => action(acc), []),
    share()
  )

  const updatedFiles$ = merge(
    updateFilenameAction$.pipe(
      map(({ fileId }) => acc => {
        return {
          ...acc,
          [fileId]: 'updating',
        }
      })
    ),
    updatedFile$.pipe(
      mergeMap(fileData =>
        timer(1600).pipe(
          mapTo(acc => {
            return Object.fromEntries(
              Object.entries(acc).filter(entry => entry[0] !== fileData.id)
            )
          }),
          startWith(acc => {
            return {
              ...acc,
              [fileData.id]: 'success',
            }
          })
        )
      )
    )
  ).pipe(
    scan((acc, action) => action(acc), {}),
    share()
  )

  const updating$ = updatedFiles$.pipe(
    map(updatedFiles =>
      Object.values(updatedFiles).some(status => status === 'updating')
    )
  )

  // TODO: add success$
  const success$ = of(void 0)

  return {
    uploading$,
    counter$,
    files$,
    updatedFiles$,
    updating$,
    success$,
  }
}
