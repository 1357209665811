<script>
import { fromEvent } from 'rxjs'
import { tap, pluck, first } from 'rxjs/operators'
import { api } from '@/api'
import { makeFileUploaderStream } from '@/common/ui.rx.js'
import XInputCheckbox from '@/components/x-input-checkbox.vue'
import XInputSlider from '@/components/x-input-slider.vue'
import XWizardEditor from '@/components/x-wizard-editor.vue'
import XFileUploader from '@/components/x-file-uploader.vue'

export default {
  data() {
    return {
      loadingJourney: false,
      form: {
        email: this.$route.params.email || this.$route.query.email || '',
        journey: {
          step: (Number(this.$route.query.step) || 1) - 1,
          5: [],
          6: [],
          13: [],
          '10-checkbox': false,
        },
      },
      loadingSaving: false,
      suboptions5: Object.freeze({
        'Backofen / Herd': {
          title: 'Welchen Backofen möchtest du?',
          options: [
            {
              label: 'UNTERBAU',
              value: 'Unterbau',
              img: '/statics/wizard/6-Unterbau.png',
              euro: 1,
            },
            {
              label: 'BRUSTHÖHE',
              value: 'Brusthöhe',
              img: '/statics/wizard/6-Brusthöhe.png',
              euro: 2,
            },
          ],
        },
        Kochfeld: {
          title: 'Welches Kochfeld möchtest du?',
          options: [
            {
              label: 'GLASKERAMIK',
              value: 'Glaskeramik',
              img: '/statics/wizard/6-Glaskeramik.png',
              euro: 1,
            },
            {
              label: 'INDUKTION',
              value: 'Induktion',
              img: '/statics/wizard/6-Induktion.png',
              euro: 2,
            },
          ],
        },
        Geschirrspüler: {
          title: 'Wie breit soll deine Spülmaschine sein?',
          options: [
            {
              label: 'KLEIN <br/> 45 CM',
              value: 'Klein',
              img: '/statics/wizard/6-Klein.png',
            },
            {
              label: 'NORMAL <br/> 60 CM',
              value: 'Normal',
              img: '/statics/wizard/6-Normal.png',
            },
          ],
        },
        Kühlschrank: {
          title: 'Welche Kühlschrank möchtest du?',
          options: [
            {
              label: 'FREISTEHEND',
              value: 'Freistehend',
              img: '/statics/wizard/6-Freistehend.png',
              euro: 1,
            },
            {
              label: 'EINBAU',
              value: 'Einbau',
              img: '/statics/wizard/6-Einbau.png',
              euro: 2,
            },
          ],
        },
        Dunstabzug: {
          title: 'Welche Dunstabzugshaube möchtest du?',
          options: [
            {
              label: 'FLACHSCHIRM',
              value: 'Flachschirm',
              img: '/statics/wizard/6-Flachschirm.png',
              euro: 1,
            },
            {
              label: 'KOPFFREIHAUBE',
              value: 'Kopffreihaube',
              img: '/statics/wizard/6-Kopffreihaube.png',
              euro: 1,
            },
          ],
        },
      }),
      suboptions6: Object.freeze({
        'Backofen / Herd': {
          title: 'Wie viele Knöpfe hat dein Ofen?',
          options: [
            {
              label: 'WENIGER AL 2',
              value: 'Weniger al 2',
              // img: '/statics/wizard/6-Unterbau.png',
              // euro: 1,
            },
            {
              label: 'MEHR ALS 2',
              value: 'Mehr als 2',
              // img: '/statics/wizard/6-Brusthöhe.png',
              // euro: 2,
            },
          ],
        },
        Kochfeld: {
          title: 'Wie breit das vorhandene Kochfeld?',
          options: [
            {
              label: 'NORMAL <br/> 60 CM',
              value: 'Normal',
              img: '/statics/wizard/6-Kochfeld-Normal.png',
            },
            {
              label: 'GROß <br/> 90 CM',
              value: 'Groß',
              img: '/statics/wizard/6-Kochfeld-Grob.png',
            },
          ],
        },
        Geschirrspüler: {
          title: 'Wie breit ist die vorhandene Spülmaschine?',
          options: [
            {
              label: 'KLEIN <br/> 45 CM',
              value: 'Klein',
              img: '/statics/wizard/6-Klein.png',
            },
            {
              label: 'NORMAL <br/> 60 CM',
              value: 'Normal',
              img: '/statics/wizard/6-Normal.png',
            },
          ],
        },
        Kühlschrank: {
          title: 'Wie breit ist der vorhandene Kühlschrank?',
          options: [
            {
              label: 'NORMAL <br/> 60 CM',
              value: 'Normal 60cm',
              img: '/statics/wizard/6-Kühlschrank-60cm.png',
            },
            {
              label: 'NORMAL <br/> 60-70 CM',
              value: 'Normal 60-70cm',
              img: '/statics/wizard/6-Kühlschrank-60-70cm.png',
            },
            {
              label: 'NORMAL <br/> 70-80 CM',
              value: 'Normal 70-80cm',
              img: '/statics/wizard/6-Kühlschrank-70-80cm.png',
            },
            {
              label: 'GROß <br/> 80-90 CM',
              value: 'Groß',
              img: '/statics/wizard/6-Kühlschrank-80-90cm.png',
            },
          ],
        },
        Dunstabzug: {
          title: 'Wie breit ist die vorhandene Dunstabzugshaube',
          options: [
            {
              label: 'NORMAL <br/> 60 CM',
              value: 'Normal',
              img: '/statics/wizard/6-Dunstabzug-Normal.png',
            },
            {
              label: 'GROß <br/> 90 CM',
              value: 'Groß',
              img: '/statics/wizard/6-Dunstabzug-Grob.png',
            },
          ],
        },
      }),
      visited: {},
      dialog5: false,
      suboptionsDialog: false,
      suboptionsData: { model: null },
      dialog12: false,
      loadingSubmit: false,
    }
  },
  created() {
    window.scrollTo(0, 0)

    this.loadingJourney = true

    api('journey', 'find', {
      url: '/find',
      method: 'POST',
      data: { email: this.form.email },
    })
      .then(data => {
        const journey =
          data.journey != void 0 ? JSON.parse(data.journey) : this.form.journey

        if (journey.step + 1 === 19) this.thankyou(journey)
        else {
          this.$set(this, 'form', {
            ...data,
            journey,
          })
          // this.next(this.form.journey.step)

          this.loadingJourney = false
        }
      })
      .catch(error => {
        console.error(error)
        this.loadingJourney = false
      })
  },
  domStreams: [
    // images uploader
    'uploadSubject',
    'removeFileSubject',
    'updateFilenameSubject',
  ],
  subscriptions() {
    // images uploader
    const uploadAction$ = this.uploadSubject.pipe(pluck('event', 'msg'))
    const removeFileAction$ = this.removeFileSubject.pipe(pluck('event', 'msg'))
    const updateFilenameAction$ = this.updateFilenameSubject.pipe(
      pluck('event', 'msg')
    )

    // https://stackoverflow.com/questions/12381563/how-can-i-stop-the-browser-back-button-using-javascript
    window.history.pushState(null, document.title, window.location.href)
    const back$ = fromEvent(window, 'popstate').pipe(
      tap(() => {
        if (this.step > 0) {
          window.history.pushState(null, document.title, window.location.href)
          this.next(this.step - 1)
        } else {
          window.history.back()
        }
      })
    )

    // images uploader

    const create = file =>
      api('upload', '', {
        method: 'POST',
        data: {
          file,
          journey: this.form.id,
          name: file.name.replace(/\.[^.]+$/, ''),
          type: file.type,
        },
      }).then(dataOrError => {
        if (dataOrError instanceof Error) return dataOrError
        delete dataOrError.journey
        return dataOrError
      })

    const update = ({ fileId, name }) =>
      api('image', 'update', {
        id: fileId,
        data: { journey: this.form.id, name },
      })

    const {
      uploading$: imagesUploading$,
      counter$: imagesCounter$,
      files$: imagesFiles$,
      updatedFiles$: imagesUpdatedFiles$,
      updating$: imagesUpdating$,
    } = makeFileUploaderStream({
      defaultFiles$: this.$watchAsObservable('form').pipe(
        pluck('newValue', 'journey', '13'),
        first()
      ),
      // defaultFiles$: of([
      //   {
      //     id: Math.random().toString(),
      //     type: 'image/png',
      //     url: `/statics/kitchens/1.png`,
      //     name: '1',
      //   },
      //   {
      //     id: Math.random().toString(),
      //     type: 'image/png',
      //     url: `/statics/kitchens/2.png`,
      //     name: '2',
      //   },
      //   {
      //     id: Math.random().toString(),
      //     type: 'image/png',
      //     url: `/statics/kitchens/1.png`,
      //     name: '1',
      //   },
      //   {
      //     id: Math.random().toString(),
      //     type: 'image/png',
      //     url: `/statics/kitchens/2.png`,
      //     name: '2',
      //   },
      // ]),
      uploadAction$,
      removeFileAction$,
      updateFilenameAction$,
      create,
      update,
    })

    return {
      back$,
      // images uploader
      imagesUploading$,
      imagesCounter$,
      imagesFiles$,
      imagesUpdatedFiles$,
      imagesUpdating$,
    }
  },
  computed: {
    step: {
      get() {
        return this.form.journey && this.form.journey.step
      },
      set(step) {
        this.form.journey && this.$set(this.form.journey, 'step', step)
      },
    },
    progress() {
      return (this.step * 100) / 18
    },
  },
  methods: {
    next(goto) {
      if (this.imagesUploading$ === true || this.imagesUpdating$ === true)
        return

      this.step = goto != void 0 && !isNaN(goto) ? Number(goto) : this.step + 1

      // reset state
      if (this.step + 1 === 6) {
        // allow only Options which are not selected on the step 5
        const values = this.form.journey['5']
        const allowed = this.form.journey['6'].filter(
          value => !values.includes(value)
        )
        const notAllowed = this.form.journey['6'].filter(value =>
          values.includes(value)
        )
        this.form.journey['6'] = allowed
        // remove suboptions
        notAllowed.forEach(value =>
          this.$delete(this.form.journey, '6-' + value)
        )
      } else if (this.step + 1 === 13 && this.form.journey['12'] != void 0) {
        // reset cancel reasons on 12 if editor was made
        this.$delete(this.form.journey, '12-cancel')
        this.$delete(this.form.journey, '12-comment')
      }

      // conditional steps
      if (this.step + 1 === 8 && this.form.journey['1'] !== 'KitchX One') {
        this.step += goto == void 0 ? 1 : -1
      } else if (
        this.step + 1 === 12 &&
        !Number(process.env.VUE_APP_DTOOL || 0)
      ) {
        this.step += goto == void 0 ? 1 : -1
      }

      // modals
      if (this.step + 1 === 5 /* && this.visited['5'] == void 0*/) {
        this.dialog5 = true
      }

      this.$set(this.visited, this.step + 1, true)

      window.scrollTo(0, 0)

      this.loadingSaving = true

      api('journey', 'save', {
        method: 'POST',
        data: {
          ...this.form,
          journey: JSON.stringify(this.form.journey),
        },
      })
        // .then(data => {
        //   this.form = {
        //     ...data,
        //     journey: data.journey || this.form.journey,
        //   }

        //   this.next(this.form.journey.step)
        // })
        // .catch(console.error)
        .finally(() => (this.loadingSaving = false))
    },
    suboptions(prefix, suboptions, [value, selected]) {
      if (suboptions[value] == void 0) return

      if (selected === false) {
        this.$delete(this.form.journey, prefix + value)
        return
      }

      const self = this
      this.suboptionsDialog = true
      this.suboptionsData = {
        ...suboptions[value],
        get model() {
          return self.form.journey[prefix + value]
        },
        set model(val) {
          self.form.journey[prefix + value] = val
          self.suboptionsDialog = false
        },
      }
    },
    submit() {
      this.loadingSubmit = true

      api('journey', 'save', {
        method: 'POST',
        data: {
          ...this.form,
          journey: JSON.stringify({
            ...this.form.journey,
            step: this.step + 1,
          }),
        },
      })
        .then(data => {
          this.$set(this, 'form', {
            ...data,
            journey: JSON.parse(data.journey),
          })

          this.thankyou(JSON.parse(data.journey))
        })
        .finally(() => (this.loadingSubmit = false))
    },
    thankyou(journey) {
      this.$router.push({
        name: 'thankyou',
        params: {
          editor: Number(journey['12'] != void 0),
          email: this.form.email,
        },
      })
    },
    random() {
      return Math.random()
    },
  },
  components: {
    XInputCheckbox,
    XInputSlider,
    XWizardEditor,
    XFileUploader,
  },
}
</script>
<template>
  <div class="px-4 px-md-12">
    <div class="mx-auto py-6 pb-10 pt-md-10 container">
      <div
        class="mb-3 d-inline-block py-1 px-3 secondary rounded text-subtitle-2 white--text"
      >
        {{ step != void 0 ? step + 1 : '--' }} von 18
      </div>
      <v-progress-linear
        background-color="grey lighten-2"
        color="secondary lighten-1"
        :value="progress || 0"
        class="rounded"
      ></v-progress-linear>

      <!-- steps container -->
      <div class="">
        <v-progress-circular
          v-if="loadingJourney === true"
          indeterminate
          color="primary"
          class="mx-auto mt-16 d-block"
        ></v-progress-circular>
        <v-tabs-items v-else v-model="step">
          <v-tab-item class="mt-10 mt-md-16">
            <h5 class="mb-8 text-h6 font-weight-bold text-raleway text-center">
              Welche Designlinie möchtest du für deine neue Küche?
            </h5>

            <x-input-checkbox
              :options="[
                {
                  value: 'KitchX Black',
                  url: '/statics/wizard/1-black.png',
                },
                {
                  value: 'KitchX One',
                  url: '/statics/wizard/1-white.png',
                },
              ]"
              :value="form.journey['1']"
              @input="$set(form.journey, '1', $event), next()"
            />

            <div class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                @click="$router.push({ name: 'home' })"
              >
                zurück
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item class="mt-10 mt-md-16">
            <h5 class="mb-8 text-h6 font-weight-bold text-raleway text-center">
              Wo befindest du dich gerade in deinem Kaufprozess?
            </h5>

            <x-input-checkbox
              :options="[
                {
                  value: 'Ich brauche schnelleine Küche',
                  url: '/statics/wizard/2-1.png',
                },
                {
                  value: 'Ich möchte vergleichen undhabe bereits Angebote',
                  url: '/statics/wizard/2-2.png',
                },
                {
                  value: 'Ich möchte michinspirieren lasse',
                  url: '/statics/wizard/2-3.png',
                },
              ]"
              :value="form.journey['2']"
              @input="$set(form.journey, '2', $event), next()"
            />

            <div class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                @click="next(step - 1)"
              >
                zurück
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item class="mt-10 mt-md-16">
            <h5 class="mb-8 text-h6 font-weight-bold text-raleway text-center">
              Warum benötigst du eine neue Küche?
            </h5>

            <x-input-checkbox
              :options="[
                {
                  value: 'Neubau',
                  url: '/statics/wizard/3-newbuilding.png',
                },
                {
                  value: 'Umzug',
                  url: '/statics/wizard/3-move.png',
                },
                {
                  value: 'Renovierung',
                  url: '/statics/wizard/3-renovation.png',
                },
              ]"
              :value="form.journey['3']"
              @input="$set(form.journey, '3', $event), next()"
            />

            <div class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                @click="next(step - 1)"
              >
                zurück
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item class="mt-10 mt-md-16">
            <h5 class="mb-8 text-h6 font-weight-bold text-raleway text-center">
              Wie kochst du am liebsten?
            </h5>

            <x-input-checkbox
              :options="[
                {
                  caption: 'Ich koche allein',
                  value: 'Ich koche allein',
                  url: '/statics/wizard/4-alone.png',
                },
                {
                  caption: 'Ich koche zu zweit',
                  value: 'Ich koche zu zweit',
                  url: '/statics/wizard/4-for2.png',
                },
                {
                  caption: 'Ich koche mit Kindern',
                  value: 'Ich koche mit Kindern',
                  url: '/statics/wizard/4-with-children.png',
                },
                {
                  caption: 'Ich koche mit Freunden',
                  value: 'Ich koche mit Freunden',
                  url: '/statics/wizard/4-with-friends.png',
                },
              ]"
              :value="form.journey['4']"
              @input="$set(form.journey, '4', $event), next()"
            />

            <div class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                @click="next(step - 1)"
              >
                zurück
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item class="mt-10 mt-md-16">
            <h5 class="mb-8 text-h6 font-weight-bold text-raleway text-center">
              Bitte wähle alle Geräte aus, welche du von kitchX kaufen möchtest.
            </h5>

            <x-input-checkbox
              multiple
              :options="[
                {
                  label: 'Backofen / Herd',
                  value: 'Backofen / Herd',
                  url: '/statics/wizard/5-1.svg',
                },
                {
                  label: 'Kochfeld',
                  value: 'Kochfeld',
                  url: '/statics/wizard/5-2.svg',
                },
                {
                  label: 'Geschirrspüler',
                  value: 'Geschirrspüler',
                  url: '/statics/wizard/5-3.svg',
                },
                {
                  label: 'Kühlschrank',
                  value: 'Kühlschrank',
                  url: '/statics/wizard/5-4.svg',
                },
                {
                  label: 'Dunstabzug',
                  value: 'Dunstabzug',
                  url: '/statics/wizard/5-5.svg',
                },
                {
                  label: 'Waschmaschine',
                  value: 'Waschmaschine',
                  url: '/statics/wizard/5-6.svg',
                },
              ]"
              :value="form.journey['5']"
              @input="$set(form.journey, '5', $event)"
              @selected="suboptions('5-', suboptions5, $event)"
              class="mx-auto"
              style="max-width: 1000px;"
            />

            <div class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                @click="next(step - 1)"
              >
                zurück
              </v-btn>
              <v-btn color="primary" depressed @click="next()" class="ml-4">
                weiter <v-icon right>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item class="mt-10 mt-md-16">
            <h5 class="mb-8 text-h6 font-weight-bold text-raleway text-center">
              Bitte wähle jetzt alle Geräte die du jetzt selber kaufen willst
              oder bereits vorhanden sind
            </h5>

            <x-input-checkbox
              multiple
              :options="
                [
                  {
                    label: 'Backofen / Herd',
                    value: 'Backofen / Herd',
                    url: '/statics/wizard/5-1.svg',
                  },
                  {
                    label: 'Kochfeld',
                    value: 'Kochfeld',
                    url: '/statics/wizard/5-2.svg',
                  },
                  {
                    label: 'Geschirrspüler',
                    value: 'Geschirrspüler',
                    url: '/statics/wizard/5-3.svg',
                  },
                  {
                    label: 'Kühlschrank',
                    value: 'Kühlschrank',
                    url: '/statics/wizard/5-4.svg',
                  },
                  {
                    label: 'Dunstabzug',
                    value: 'Dunstabzug',
                    url: '/statics/wizard/5-5.svg',
                  },
                  {
                    label: 'Waschmaschine',
                    value: 'Waschmaschine',
                    url: '/statics/wizard/5-6.svg',
                  },
                ].filter(option => !form.journey['5'].includes(option.value))
              "
              :value="form.journey['6']"
              @input="$set(form.journey, '6', $event)"
              @selected="suboptions('6-', suboptions6, $event)"
              class="mx-auto"
              style="max-width: 1000px;"
            />

            <div class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                @click="next(step - 1)"
              >
                zurück
              </v-btn>
              <v-btn color="primary" depressed @click="next()" class="ml-4">
                weiter <v-icon right>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item class="mt-10 mt-md-16">
            <h5 class="mb-8 text-h6 font-weight-bold text-raleway text-center">
              Welche E-Geräte Marke möchtest du?
            </h5>

            <x-input-slider
              :options="[
                {
                  'top-label': 'Einstiegs E-Geräte',
                  label: 'LAURUS / LEONARD',
                  'black-label': true,
                  value: 'Einstiegs E-Geräte',
                  info: 'Beliebteste Einstiegsmarke',
                },
                {
                  'top-label': 'Premium E-Geräte',
                  label: 'SIEMENS',
                  'black-label': true,
                  value: 'Premium E-Geräte',
                },
                {
                  'top-label': 'Smart Geräte',
                  label: 'SIEMENS',
                  'black-label': true,
                  'label-icon': 'mdi-wifi',
                  value: 'Smart Geräte',
                  info: 'Smart Geräte von Siemens für die Küche der Zukunft',
                },
              ]"
              :value="form.journey['7']"
              @input="
                $set(form.journey, 7, $event)
                next()
              "
            />

            <div class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                @click="next(step - 1)"
              >
                zurück
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item class="mt-10 mt-md-16">
            <h5 class="mb-3 text-h6 font-weight-bold text-raleway text-center">
              Welche Arbeitsplatte gefällt dir?
            </h5>

            <p
              class="mx-auto mb-8 font-weight-light text-center"
              style="max-width: 644px;"
            >
              Die KitchX One kommt immer mit weißer matter Front und
              Edelstahlgriffen. Bei der Arbeitsplatte gibt es zwei Varianten:
              Einmal Eiche Provence und einmal Beton Schiefergrau.
            </p>

            <x-input-checkbox
              :options="[
                {
                  badge: 'Top seller!',
                  value: 'Beton Schiefergrau',
                  url: '/statics/wizard/8-1.png',
                },
                {
                  value: 'Eiche Provence',
                  url: '/statics/wizard/8-2.png',
                },
              ]"
              :value="form.journey['8']"
              @input="$set(form.journey, '8', $event), next()"
            />

            <div class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                @click="next(step - 1)"
              >
                zurück
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item class="mt-10 mt-md-16">
            <h5 class="mb-8 text-h6 font-weight-bold text-raleway text-center">
              Wie sollen deine Hängeschränke geplant werden?
            </h5>

            <x-input-slider
              outerbox
              :options="[
                {
                  'top-img': '/statics/wizard/9-1.png',
                  label: 'Ich möchte über jedem Schrank einen Oberschrank',
                  value: 'Ich möchte über jedem Schrank einen Oberschrank',
                  sublabel:
                    'Ideal, wenn du viel Stauraum benötigst oder viel Arbeitsfläche möchtest',
                },
                {
                  'top-img': '/statics/wizard/9-2.png',
                  label: 'Oberschränke mit freien <br/> Flächen',
                  value: 'Oberschränke mit freien Flächen',
                  sublabel:
                    'Ideal, wenn Dein Raum etwas kleiner ist und Du Deine Küche etwas freier möchtest',
                },
                {
                  'top-img': '/statics/wizard/9-3.png',
                  label: 'Ich möchte keine <br/> Oberschränke',
                  value: 'Ich möchte keine Oberschränke',
                  sublabel:
                    'Ideal für große Küchen aber auch in kleinen Räumen und besonders in schmalen Räumen. Macht die Küche weniger wuchtig und lässt den Raum größer wirken',
                },
              ]"
              :value="form.journey['9']"
              @input="$set(form.journey, '9', $event), next()"
              class="mx-auto"
              style="max-width: 1000px;"
            />

            <div class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                @click="next(step - 1)"
              >
                zurück
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item class="mt-10 mt-md-16">
            <h5 class="mb-8 text-h6 font-weight-bold text-raleway text-center">
              Was ist dein Budget für deine Küche?
            </h5>

            <x-input-slider
              :options="[
                {
                  label: '3.000€ - 4.000€',
                  value: '3.000€ - 4.000€',
                },
                {
                  label: '4.000€ - 5.000€',
                  value: '4.000€ - 5.000€',
                },
                {
                  label: '5.000€ - 6.000€',
                  value: '5.000€ - 6.000€',
                },
                {
                  label: '6.000€-8.000€',
                  value: '6.000€-8.000€',
                },
                {
                  label: 'Über 8.000€',
                  value: 'Über 8.000€',
                },
              ]"
              :value="form.journey['10']"
              @input="$set(form.journey, '10', $event)"
            />

            <div class="mt-6 text-center">
              <v-checkbox
                dense
                hide-details
                v-model="form.journey['10-checkbox']"
                label="Ich möchte gern in Raten zahlen"
                class="mt-0 pt-0 d-inline-block"
              ></v-checkbox>
            </div>

            <form @submit.prevent="next()" class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                @click="next(step - 1)"
              >
                zurück
              </v-btn>
              <input
                required
                name="as"
                :value="
                  form.journey['10'] || form.journey['10-checkbox'] ? 'ok' : ''
                "
                style="opacity: 0; width: 1px; height: 1px; position: absolute; bottom: 7.5rem;"
              />
              <v-btn type="submit" color="primary" depressed class="ml-4">
                weiter <v-icon right>mdi-chevron-right</v-icon>
              </v-btn>
            </form>
          </v-tab-item>

          <v-tab-item class="mt-10 mt-md-16">
            <h5 class="mb-3 text-h6 font-weight-bold text-raleway text-center">
              Welche Faktoren sind Dir bei deiner Küche besonders wichtig?
            </h5>

            <p
              class="mx-auto mb-8 font-weight-light text-center"
              style="max-width: 644px;"
            >
              Wir werden immer unser bestes tun, alle Deine Wünsche in der
              Küchenplanung zu berücksichtigen.
            </p>

            <x-input-checkbox
              multiple
              dense
              :options="[
                {
                  label: 'Extra viel Stauraum',
                  value: 'Extra viel Stauraum',
                },
                {
                  label: 'So viel Arbeitsfläche wie möglich',
                  value: 'So viel Arbeitsfläche wie möglich',
                },
                {
                  label: 'Speziell fürs Kochen optimiert',
                  value: 'Speziell fürs Kochen optimiert',
                },
                {
                  label: 'Extra breite Schränke',
                  value: 'Extra breite Schränke',
                },
                {
                  label: 'Möglichst viele Hochschränke',
                  value: 'Möglichst viele Hochschränke',
                },
                {
                  label: 'Viele Schubladenschränke',
                  value: 'Viele Schubladenschränke',
                },
                {
                  label: 'So günstig wie möglich',
                  value: 'So günstig wie möglich',
                },
                {
                  label: 'Besonders energieffiziente Gerätee',
                  value: 'Besonders energieffiziente Gerätee',
                },
                {
                  label: 'Besonders leise Geräte',
                  value: 'Besonders leise Geräte',
                },
                {
                  label: 'Optimiert für große Leute >185cm',
                  value: 'Optimiert für große Leute >185cm',
                },
              ]"
              :value="form.journey['11']"
              @input="$set(form.journey, '11', $event)"
              style="max-width: 700px;"
              min-height="89px"
              class="mx-auto"
            />

            <div class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                @click="next(step - 1)"
              >
                zurück
              </v-btn>
              <v-btn color="primary" depressed @click="next()" class="ml-4">
                weiter <v-icon right>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item class="mt-5">
            <x-wizard-editor
              :key="random()"
              @back="next(step - 1)"
              @cancel="dialog12 = true"
              @finish="next()"
              @input="$set(form.journey, '12', $event)"
            ></x-wizard-editor>
          </v-tab-item>

          <v-tab-item class="mt-10 mt-md-16">
            <h5 class="mb-3 text-h6 font-weight-bold text-raleway text-center">
              Rauminformationen hochladen
            </h5>

            <p
              class="mx-auto mb-8 font-weight-light text-center"
              style="max-width: 644px;"
            >
              {{
                form.journey['13'] && form.journey['13'].length
                  ? 'Benötigen wir von dir Angaben zu deinem Küchenraum und Maße'
                  : 'Lade jetzt Bilder Deines Küchenraumes hoch. Bitte stelle sicher, dass Wasser- und Stromanschlüsse klar ersichtlich sind'
              }}
            </p>

            <x-file-uploader
              v-model="form.journey['13']"
              :files="imagesFiles$"
              :updatedFiles="imagesUpdatedFiles$"
              :uploading="imagesUploading$"
              :counter="imagesCounter$"
              v-stream:upload="uploadSubject"
              v-stream:removeFile="removeFileSubject"
              v-stream:updateFilename="updateFilenameSubject"
              upload-label="Weitere Dateien hochladen"
              icon="mdi-upload"
              :size="237"
              class="mx-auto"
              style="max-width: 800px;"
            >
            </x-file-uploader>

            <div class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                :disabled="imagesUploading$"
                @click="next(step - 1)"
              >
                zurück
              </v-btn>
              <v-btn
                color="primary"
                depressed
                :disabled="imagesUploading$"
                @click="next()"
                class="ml-4"
              >
                Weiter
                <v-icon right>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item class="mt-10 mt-md-16">
            <h5 class="mb-8 text-h6 font-weight-bold text-raleway text-center">
              Wann benötigst du deine Küche?
            </h5>

            <x-input-slider
              :options="[
                {
                  label: 'in unter 4 Wochen',
                  value: 'in unter 4 Wochen',
                },
                {
                  label: 'in 4 bis 8 Wochen',
                  value: 'in 4 bis 8 Wochen',
                },
                {
                  label: 'in 8 bis 12 Wochen',
                  value: 'in 8 bis 12 Wochen',
                },
                {
                  label: 'in über 12 Wochen',
                  value: 'in über 12 Wochen',
                },
              ]"
              :value="form.journey['14']"
              @input="$set(form.journey, '14', $event), next()"
            />

            <div class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                @click="next(step - 1)"
              >
                zurück
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item class="mt-10 mt-md-16">
            <h5 class="mb-8 text-h6 font-weight-bold text-raleway text-center">
              Benötigst du einen Montageservice?
            </h5>

            <x-input-checkbox
              dense
              :options="[
                {
                  label: 'JA',
                  value: 'JA',
                },
                {
                  label: 'NEIN',
                  value: 'NEIN',
                },
              ]"
              :value="form.journey['15']"
              @input="$set(form.journey, '15', $event), next()"
            />

            <div class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                @click="next(step - 1)"
              >
                zurück
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item class="mt-10 mt-md-16">
            <h5 class="mb-8 text-h6 font-weight-bold text-raleway text-center">
              Benötigst du einen Demontageservice für eine bestehende Küche?
            </h5>

            <x-input-checkbox
              dense
              :options="[
                {
                  label: 'JA',
                  value: 'JA',
                },
                {
                  label: 'NEIN',
                  value: 'NEIN',
                },
              ]"
              :value="form.journey['16']"
              @input="$set(form.journey, '16', $event), next()"
            />

            <div class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                @click="next(step - 1)"
              >
                zurück
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item class="mt-10 mt-md-16">
            <h5 class="mb-8 text-h6 font-weight-bold text-raleway text-center">
              Wie möchtest du kontaktiert werden?
            </h5>

            <img
              src="/statics/wizard/17-hero.png"
              width="422"
              height="200"
              alt="Wizard image"
              class="mb-8 mx-auto d-block img-fluid"
            />

            <x-input-checkbox
              dense
              :options="[
                {
                  label: 'WHATSAPP',
                  value: 'WHATSAPP',
                },
                {
                  label: 'TELEFON',
                  value: 'TELEFON',
                },
              ]"
              :value="form.journey['17']"
              @input="$set(form.journey, '17', $event), next()"
            />

            <div class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                @click="next(step - 1)"
              >
                zurück
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item class="mx-auto mt-10 mt-md-16" style="max-width: 700px;">
            <form @submit.prevent="submit">
              <h5
                class="mb-8 text-h6 font-weight-bold text-raleway text-center"
              >
                "Super! Wir haben alle Informationen, um Deine Küche planen zu
                können. An wen sollen wir Dein unverbindliches Angebot
                schicken?"
              </h5>

              <div class="mb-6 d-flex justify-center">
                <div class="mr-8 font-weight-semibold">
                  Anrede
                </div>
                <v-radio-group
                  dense
                  required
                  hide-details
                  v-model="form.journey['18-anrede']"
                  :column="false"
                  class="mt-0 pt-0"
                >
                  <v-radio
                    :value="'Herr'"
                    dense
                    hide-details
                    class="mt-0 pt-0 mr-4"
                    label="Herr"
                  ></v-radio>
                  <v-radio
                    :value="'Frau'"
                    dense
                    hide-details
                    class="mt-0 pt-0"
                    label="Frau"
                  ></v-radio>
                </v-radio-group>
              </div>

              <div class="row no-gutters">
                <v-text-field
                  dense
                  outlined
                  required
                  v-model="form.journey['18-vorname']"
                  label="Vorname"
                  name="Vorname"
                  placeholder="Vorname"
                  class="col-12 col-md-6"
                ></v-text-field>

                <v-text-field
                  dense
                  outlined
                  v-model="form.journey['18-nachname']"
                  label="Nachname"
                  name="Nachname"
                  placeholder="Nachname"
                  class="col-12 col-md-6 pl-md-2"
                ></v-text-field>

                <v-text-field
                  disabled
                  dense
                  outlined
                  v-model="form.email"
                  label="Email"
                  name="Email"
                  placeholder="Email"
                  class="col-12 col-md-8"
                ></v-text-field>

                <v-text-field
                  dense
                  outlined
                  required
                  v-model="form.journey['18-PLZ']"
                  label="PLZ"
                  name="PLZ"
                  placeholder="PLZ"
                  class="col-12 col-md-4 pl-md-2"
                  maxlength="5"
                ></v-text-field>

                <v-text-field
                  dense
                  outlined
                  required
                  v-model="form.journey['18-handy']"
                  label="Handy"
                  name="Handy"
                  placeholder="Handy"
                  class="col-12"
                ></v-text-field>
              </div>

              <p class="mb-0 mt-4 text-caption font-weight-light text-center">
                Wenn Du auf den oberen Button klickst, akzeptierst Du unsere
                AGBs & Datenschutzbestimmungen. Deine Angaben und
                Kontaktinformationen werden zur Erbringung unserer
                Beratungsleistung genutzt. Du akzeptierst auch, im Rahmen Deiner
                Anfrage Informationen und Angebote von uns per E-Mail, Telefon
                und Kurznachricht zu erhalten. Du kannst die
                Einwilligungserklärung jederzeit mit Wirkung für die Zukunft
                widerrufen.
              </p>

              <div class="mt-8 text-center">
                <v-btn
                  color="grey darken-1"
                  outlined
                  depressed
                  @click="next(step - 1)"
                >
                  zurück
                </v-btn>
                <v-btn
                  type="submit"
                  color="primary"
                  depressed
                  :loading="loadingSubmit"
                  class="ml-md-4 mt-4 mt-md-0"
                >
                  Jetzt unverbindliches Angebot erhalten
                  <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </form>
          </v-tab-item>

          <!-- <v-tab-item>
            <h5 class="mb-8 text-h6 font-weight-bold text-raleway text-center">
              ---
            </h5>

            <div class="mt-8 text-center">
              <v-btn
                color="grey darken-1"
                outlined
                depressed
                @click="next(step - 1)"
              >
                zurück
              </v-btn>
            </div>
          </v-tab-item> -->
        </v-tabs-items>
      </div>
    </div>

    <v-dialog
      v-model="dialog5"
      max-width="414px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
      content-class="white px-6 py-8"
    >
      <p class="mb-8 text-center" style="">
        Bei kitchX geben wir dir alle Freiheiten. Deshalb hast du bei den
        <b>Geräten 2 Möglichkeiten</b>: <br /><br />
        1) Du kannst die Geräte bei uns kaufen und wir verbauen sie in Deiner
        neuen Küche.
        <br /><br />
        oder
        <br /><br />
        2) Du <b>kaufst die Geräte selber</b> und stellst sie uns zur Verfügung.
        Wir bauen sie dann in Deiner neuen Küche ein. <br /><br />
        Bitte wähle im <b>nächsten Schritt</b> alle Geräte aus, welche Du
        <b>von kitchX kaufen</b> möchtest.
      </p>

      <div class="text-center">
        <v-btn color="primary" depressed @click="dialog5 = false">
          Verstanden
        </v-btn>
      </div>
    </v-dialog>

    <v-dialog
      v-model="suboptionsDialog"
      max-width="464px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
      content-class="white px-6 py-8"
    >
      <p class="mb-6 text-center font-weight-bold" style="">
        {{ suboptionsData && suboptionsData.title }}
      </p>

      <div
        class="mb-8"
        style="
          display: grid;
          justify-content: center;
          grid-gap: 1.5rem;
          grid-template-columns: repeat(auto-fit, 150px);
        "
      >
        <div
          v-for="suboption in suboptionsData && suboptionsData.options"
          :key="suboption.value"
          :class="[
            'pa-4 d-flex flex-column align-center justify-center text-center rounded cursor-pointer font-weight-semibold',
          ]"
          style="position: relative; border: 1px solid #dadada;"
          @click="suboptionsData.model = suboption.value"
        >
          <span
            class=""
            style="line-height: 1.3rem;"
            v-html="suboption.label"
          ></span>
          <img v-if="suboption.img" :src="suboption.img" class="mt-2" />
          <p
            v-else-if="suboption.sublabel"
            class="mb-0 text-h6 secondary--text"
            v-text="suboption.sublabel"
          ></p>
          <span
            v-if="suboption.euro"
            class="secondary--text text-h6"
            style="position: absolute; bottom: 2px; right: 7px; line-height: normal;"
            >{{ '€'.repeat(suboption.euro) }}</span
          >
        </div>
      </div>

      <div class="text-center">
        <v-btn
          color="grey darken-1"
          outlined
          depressed
          @click="suboptionsDialog = false"
        >
          zurück
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="suboptionsDialog = false"
          class="ml-4"
        >
          weiter
        </v-btn>
      </div>
    </v-dialog>

    <v-dialog
      v-model="dialog12"
      persistent
      max-width="714px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
      content-class="white px-6 py-8"
    >
      <p
        class="mx-auto mb-8 text-center font-weight-bold"
        style="max-width: 500px; "
      >
        Ok, kein Problem. Lass uns bitte noch kurz wissen, warum Du die Eingaben
        jetzt nicht machen möchtest.
      </p>

      <div class="mb-6 d-flex justify-center">
        <v-radio-group
          dense
          hide-details
          column
          v-model="form.journey['12-cancel']"
          class="mt-0 pt-0"
        >
          <v-radio
            v-for="suboption in [
              'Ich hab noch keine genauen Maße und möchte die Maße später eingeben',
              'Ich finde die Eingabe zu umständlich',
              'Mir ist die Eingabe nicht klar',
              'Ich habe gerade keine Zeit, alles einzugeben',
              'Ich habe keine genauen Maße und benötige ein Aufmaß',
            ]"
            :key="suboption"
            :value="suboption"
            dense
            hide-details
            :label="suboption"
            style="font-size: 14px !important;"
          ></v-radio>
        </v-radio-group>
      </div>

      <div class="mx-auto px-6 mb-8" style="max-width: 596px;">
        <p class="mb-2 ml-1 font-weight-bold">
          Anderer grund?
        </p>

        <v-text-field
          v-model="form.journey['12-comment']"
          counter
          outlined
          hide-details
          placeholder="Anderer Grund"
        ></v-text-field>
      </div>

      <div class="text-center">
        <v-btn
          color="grey darken-1"
          outlined
          depressed
          @click=";(dialog12 = false), next()"
        >
          remove
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click=";(dialog12 = false), next()"
          class="ml-4"
        >
          Fertig
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>
