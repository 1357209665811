<script>
import { state as authState } from '@/store/auth.js'

export default {
  provide() {
    return {
      auth: authState,
    }
  },
  created() {
    window.fallbackKitchen = img => (img.src = '/statics/placeholder.png')
  },
}
</script>
<template>
  <router-view></router-view>
</template>
