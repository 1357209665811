export const replaceParams = (url, object) =>
  Object.entries(object).reduce(
    (acc, curr) => acc.replace(`:${curr[0]}`, curr[1]),
    url
  )

export const makeFormData = object => {
  let formData = new FormData()

  Object.entries(object)
    .filter(entry => entry[1] != void 0)
    .flatMap(([key, value]) =>
      Array.isArray(value) && value.every(value => value instanceof File)
        ? value.map(file => [key, file])
        : [[key, value]]
    )
    .forEach(entry => {
      FormData.prototype.append.apply(formData, entry)
    })

  return formData
}
