export function pipe(...pipeline) {
  return value => pipeline.reduce((value, fn) => fn(value), value)
}

export function pipeWith(value, ...pipeline) {
  return pipe(...pipeline)(value)
}

export function identity(baton) {
  return baton
}

export const shuffle = array => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export const range = (start, end) =>
  Array(end + 1 - start)
    .fill(0)
    .map((_, index) => start + index)

export const randomRange = (min, max) =>
  Math.floor(Math.random() * (max - min) + min)

export const isObject = object =>
  object != null && typeof object === 'object' && object.constructor === Object

export const notNull = val => val != void 0

export const isNull = val => val == void 0

export const notEmpty = val => val != void 0 && val !== ''

export const prop = (obj, ...props) => {
  if (isNull(obj)) return void 0

  props = props.reverse()
  for (let i = props.length - 1; i >= 0; --i) {
    if (isNull(obj[props[i]])) return void 0
    obj = obj[props[i]]
  }

  return obj
}

export const has = (obj, ...props) => {
  return notNull(prop(obj, ...props))
}

export const eqJSON = (a, b) => JSON.stringify(a) === JSON.stringify(b)

export const csvArray2Objects = csvArray => {
  const headers = csvArray[0].map(name => name.toLowerCase().replace(/ /g, '_'))
  return csvArray
    .slice(1)
    .map(row =>
      Object.fromEntries(headers.map((header, index) => [header, row[index]]))
    )
}
