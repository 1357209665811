export default {
  Küchenformen: [
    {
      value: 'square-bottom',
      url: '/statics/wizard/12-shapes-square-bottom.png',
    },
    {
      value: 'square-double',
      url: '/statics/wizard/12-shapes-square-double.png',
    },
    {
      value: 'square-Lright',
      url: '/statics/wizard/12-shapes-square-Lright.png',
    },
    {
      value: 'square-Lleft',
      url: '/statics/wizard/12-shapes-square-Lleft.png',
    },
    {
      value: 'square-U',
      url: '/statics/wizard/12-shapes-square-U.png',
    },
  ],
}
