var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"grid","justify-content":"center"},style:({
    'grid-gap': _vm.gridGap,
    'grid-template-columns':
      _vm.dense === true ? 'repeat(auto-fit, 150px)' : 'repeat(auto-fit, 300px)',
  })},_vm._l((_vm.options),function(option){return _c('div',{key:option.value,class:[
      'pa-3 rounded cursor-pointer hover:secondary',
      {
        'secondary white--text':
          _vm.multiple === true
            ? _vm.dense === true && (_vm.value || []).includes(option.value)
            : _vm.value === option.value,
      },
      { 'd-flex align-center justify-center text-center': _vm.dense === true } ],staticStyle:{"border":"1px solid #dadada","position":"relative"},style:({ minHeight: _vm.minHeight }),on:{"click":function($event){_vm.$emit(
        'input',
        _vm.multiple === true
          ? (_vm.value || []).includes(option.value)
            ? (_vm.value || []).filter(function (val) { return val !== option.value; })
            : (_vm.value || []).concat(option.value)
          : option.value
      )
      _vm.$emit('selected', [
        option.value,
        _vm.multiple === true ? !(_vm.value || []).includes(option.value) : true ])}}},[(_vm.multiple === true && _vm.dense === false)?_c('div',{staticClass:"mb-2"},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"input-value":(_vm.value || []).includes(option.value),"dense":"","hide-details":"","label":option.label}})],1):(_vm.dense === true)?_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(option.label)+" ")]):_vm._e(),(option.url)?_c('img',{staticClass:"rounded img-fluid",attrs:{"src":option.url,"alt":"Design Thumbnail","width":"276","height":"208"}}):_vm._e(),(option.badge != void 0)?_c('div',{staticClass:"pa-3 pb-5 font-weight-semibold text-caption white--text",staticStyle:{"position":"absolute","top":"8px","left":"8px","font-size":"18px"}},[_c('div',{staticClass:"d-flex px-3 py-1 rounded align-center justify-center",staticStyle:{"background":"rgba(206, 39, 39, 0.8)","border":"1px solid white"}},[_vm._v(" "+_vm._s(option.badge)+" ")])]):_vm._e(),(option.caption != void 0)?_c('caption',{staticClass:"pa-3 pb-5 font-weight-semibold white--text",staticStyle:{"position":"absolute","width":"100%","bottom":"0","left":"0","right":"0","font-size":"18px"}},[_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"min-height":"48px","background":"linear-gradient(360deg, #222425 0%, rgba(34, 36, 37, 0) 100%)"}},[_vm._v(" "+_vm._s(option.caption)+" ")])]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }