<script>
import XSteps from '@/components/x-steps.vue'
// import Types from '@/common/types.js'

export default {
  data() {
    return {
      email: this.$route.query.email || '',
      steps: [
        {
          title: 'Küchenanfrage',
          text:
            'Beantworte uns in 3 Minuten ein paar Fragen zu Deiner Küche und erkläre uns, wie Du Deine Küche ausstatten willst',
        },
        {
          title: 'Raummaße',
          text:
            'Um dir ein genaues Angebot machen zu können, benötigen wir jetzt im nächsten Schritt nur noch die Maße Deiner Küche. Danach übernehmen wir.',
        },
        {
          title: 'Angebot',
          text:
            'Innerhalb von 24 Stunden erhältst Du von uns ein unverbindliches Angebot. Dafür werden wir Dich per WhatsApp oder Telefon vorab kontaktieren, um kurz in 5 Minuten Deine finalen Details zu besprechen..',
        },
      ],
      // Types,
    }
  },
  components: {
    XSteps,
  },
}
</script>
<template>
  <div class="px-4 px-md-12">
    <form
      @submit.prevent="$router.push({ name: 'wizard', query: { email } })"
      class="mx-auto pt-10 pt-md-16"
      style="max-width: 644px;"
    >
      <img
        src="/statics/home-welcome.png"
        alt="Hero image"
        width="644"
        height="200"
        class="img-fluid mb-6 mt-md-4"
      />

      <p class="mb-6 font-weight-light text-center">
        Kein verstaubtes Küchenstudio und kein langen Wartezeiten. Bei kitchX
        kannst Du deine Küche bequem von Deiner Couch aus bestellen. Gib jetzt
        in <span class="font-weight-bold">10 Minuten</span> deine Wünsche ein
        und erhalte bereits morgen Dein individuelles und unverbindliches
        Angebot.
      </p>

      <v-text-field
        type="email"
        name="email"
        v-model="email"
        label="Email"
        placeholder="Email"
        outlined
        required
        hide-details
        class="mb-6 mx-auto"
        style="max-width: 20rem;"
      ></v-text-field>

      <p class="mb-4 text-caption font-weight-bold text-center">
        Geben Sie Ihre E-Mail-Adresse ein, um Ihren Fortschritt zu speichern
      </p>

      <div class="text-center">
        <v-btn type="submit" color="primary" depressed>
          los gehts <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </form>

    <x-steps class="mt-16 mb-10" :steps="steps" :current="0"></x-steps>
  </div>
</template>
