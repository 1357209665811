import { createApi } from '@/common/api.js'

const context = require.context('.', true, /\.js$/)
const endpointModules = Object.fromEntries(
  context
    .keys()
    .filter(key => !key.includes('index'))
    .map(function(key) {
      const [, filename] = key.match(/([\w]+)\.js/)
      return [filename, context(key)]
    })
)

const defaultActions = {
  find: {
    url: '/find/:id',
    dataIf: url => url === '/find/0',
    data: ({ endpoint, bypass }) =>
      endpoint.empty == void 0 ? bypass : Object.copy(endpoint.empty),
  },
  create: {
    method: 'POST',
  },
  update: {
    method: 'PUT',
    url: '/update/:id',
  },
}

const defaultConfig = {
  API: process.env.VUE_APP_API,
  unwrapData: 'data',
  validCodes: [200, 201],
  throttle: [100, 200],
}

export const api = createApi(endpointModules, defaultActions, defaultConfig)
