<script>
export default {
  props: {
    files: {
      type: Array,
      default: Array,
    },
    updatedFiles: Object,
    success: Boolean,
    uploading: Boolean,
    counter: String,
    multiple: {
      type: Boolean,
      default: true,
    },
    uploadLabel: {
      type: String,
      default: 'upload file',
    },
    accept: {
      type: String,
      default: 'image/*',
    },
    readonly: Boolean,
    size: {
      type: Number,
      default: 225,
    },
    btnClass: {
      type: String,
      default: 'text-center',
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: void 0,
    },
  },
  methods: {
    upload(event) {
      this.$emit(
        'upload',
        Array.from(
          event.type === 'drop' ? event.dataTransfer.files : event.target.files
        )
      )

      this.$refs.inputFile.value = ''
    },
    updateFilename(event) {
      this.$emit('updateFilename', {
        fileId: event.target.dataset.id,
        name: event.target.value,
      })
    },
  },
  watch: {
    files: {
      immediate: true,
      handler(files) {
        this.$emit('input', files)
      },
    },
  },
}
</script>
<template>
  <div @dragover.prevent @drop.prevent="upload">
    <div
      v-if="files"
      class="mb-12"
      :style="{
        display: 'grid',
        'grid-template-columns': `repeat(auto-fill, minmax(${size}px, 1fr))`,
        'grid-gap': '2rem 1.5rem',
      }"
    >
      <div v-for="(file, index) in files" :key="file.id">
        <div
          class="grey lighten-2 rounded"
          style="z-index: 1; --aspect-ratio: 237/160;"
        >
          <img
            :src="(file && file.url) || '/statics/placeholder.png'"
            class="rounded"
            width="237"
            height="160"
            style="object-fit: cover;"
            alt="Image preview"
          />
          <v-btn
            v-if="!readonly"
            x-small
            fab
            depressed
            style="position: absolute; top: 10px; right: 10px; padding: 0; margin: 0;"
            @click="$emit('removeFile', index)"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
          <v-btn
            v-if="downloadable"
            type="a"
            :href="file && file.url"
            download=""
            target="_blank"
            x-small
            fab
            depressed
            style="position: absolute; top: 50px; right: 10px; padding: 0; margin: 0;"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>

        <v-text-field
          v-model="file.name"
          dense
          outlined
          hide-details
          label="Gib hier deine Wünsche..."
          name="name"
          :data-id="file.id"
          @change="updateFilename"
          :disabled="
            (updatedFiles && updatedFiles[file.id] === 'updating') ||
              readonly ||
              true
          "
          class="mt-4"
          style="width: 100%;"
        >
          <template v-slot:append>
            <v-progress-circular
              v-if="updatedFiles && updatedFiles[file.id] === 'updating'"
              color="secondary lighten-1"
              size="16"
            ></v-progress-circular>

            <p
              v-else-if="updatedFiles && updatedFiles[file.id] === 'success'"
              class="mb-2 mr-1 text-caption text-uppercase success--text font-weight-medium"
              style="align-self: flex-end;"
            >
              saved
            </p>
          </template>
        </v-text-field>
      </div>

      <div
        :class="btnClass"
        style="
          min-height: 170px;
          border: 1px dashed rgba(166, 155, 146, 0.6);
          border-radius: 4px;
          display: grid;
          place-content: center;
        "
        :style="{ 'grid-column': files.length === 0 ? '1 / -1' : 'unset' }"
      >
        <button
          v-if="
            !readonly &&
              (multiple ||
                (multiple === false &&
                  (files === void 0 || (files && files.length === 0))))
          "
          @click="$refs.inputFile.click()"
          style="max-width: 12rem; padding: 12px 16px; border: 1px solid rgba(166, 155, 146, 0.6); border-radius: 4px; text-align: left;"
          class="d-flex align-center secondary--text"
          :disabled="multiple === false && files && files.length > 0"
        >
          <v-icon v-if="icon" left color="secondary">{{ icon }}</v-icon>
          {{ uploading ? `Uploading... (${counter})` : uploadLabel }}
        </button>
        <!-- <slot name="actions"></slot> -->
      </div>
    </div>
    <slot v-else></slot>

    <input
      type="file"
      :multiple="multiple"
      @input="upload"
      ref="inputFile"
      class="d-none"
      :accept="accept"
    />
  </div>
</template>
