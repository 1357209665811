import Vue from 'vue'
import VueRx from 'vue-rx'
import App from './app.vue'
import router from './router'
import vuetify from './plugins/vuetify.js'
import { initAuth } from '@/store/auth.js'

Vue.config.productionTip = false

import './styles/utils.css'

Vue.use(VueRx)

Vue.mixin({
  data() {
    return {
      Error: window.Error,
    }
  },
})

initAuth()

// if (
//   process.env.NODE_ENV === 'development' ||
//   window.prompt('Password') === 'xblack'
// ) {
new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
// }
