import Vue from 'vue'
import { VRow } from 'vuetify/lib'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.grey.darken4,
        secondary: '#A69B92',
      },
    },
    options: { customProperties: true },
  },
  components: {
    VRow,
  },
})
