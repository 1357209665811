var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"grid","grid-template-columns":"repeat(auto-fit, 300px)","justify-content":"center","grid-gap":"1.5rem"}},_vm._l((_vm.steps),function(step,index){return _c('div',{key:step.title,staticClass:"pa-5 rounded-lg",style:({
      border:
        _vm.current === index || step.done
          ? ((step.done ? '2' : '1') + "px solid " + (step.done ? '#6FCF97' : '#A69B92'))
          : 'unset',
    })},[_c('div',[_c('p',{class:[
          'mb-3 d-inline-flex justify-center align-center rounded-circle',
          _vm.current === index || step.done
            ? 'font-weight-bold'
            : 'font-weight-light',
          { primary: _vm.current === index || step.done },
          { 'white--text': _vm.current === index || step.done } ],staticStyle:{"width":"32px","height":"32px"},style:({
          border: step.done ? '2px solid #6FCF97 !important' : 'unset',
        })},[_vm._v(" "+_vm._s(index + 1)+" ")]),(step.done)?_c('v-icon',{staticClass:"mt-1 float-right",attrs:{"color":"#6FCF97"}},[_vm._v(" mdi-check ")]):_vm._e(),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(step.title)+" ")])],1),_c('div',{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s(step.text)+" ")])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }