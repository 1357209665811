<script>
export default {
  props: {
    options: Array,
    value: String,
    outerbox: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<template>
  <!--
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-items: center;
  -->
  <div
    style="
      display: grid;
      justify-content: center;
    "
    :style="{
      'grid-template-columns': $vuetify.breakpoint.mdAndUp
        ? `repeat(${options.length}, 1fr)`
        : 'repeat(auto-fit, 300px)',
      'grid-gap': options.some(
        option =>
          option['top-label'] ||
          option['top-img'] ||
          option.info ||
          option.sublabel
      )
        ? '3rem 1.5rem'
        : '1rem 1.5rem',
    }"
  >
    <div
      v-for="option in options"
      :key="option.value"
      :class="['pa-3 pa-md-3 rounded', { 'cursor-pointer': outerbox }]"
      :style="{ border: outerbox ? '1px solid #dadada' : 'unset' }"
      @click="outerbox ? $emit('input', option.value) : void 0"
    >
      <div
        v-if="option['top-label'] != void 0"
        class="mb-4 mb-md-8 text-center text-h6"
      >
        {{ option['top-label'] }}
      </div>

      <img
        v-if="option['top-img']"
        :src="option['top-img']"
        alt="Thumbnail"
        width="290"
        height="111"
        class="mx-auto mb-4 d-block rounded img-fluid"
      />

      <div
        :class="[
          'px-4 d-flex align-center justify-center font-weight-semibold rounded-lg cursor-pointer hover:secondary hover:white--text text-center',
          !outerbox ? 'py-4' : 'py-2',
          {
            'text-h6': option['black-label'] === true,
            secondary: value === option.value,
            'white--text': value === option.value,
          },
        ]"
        :style="{ border: !outerbox ? '1px solid #dadada' : 'unset' }"
        @click.stop="$emit('input', option.value)"
      >
        <v-icon
          v-if="option['label-icon']"
          dense
          class="mr-2"
          :color="value === option.value ? 'white' : void 0"
        >
          {{ option['label-icon'] }}
        </v-icon>
        <span class="" v-html="option.label"></span>
      </div>

      <div
        v-if="option.info != void 0"
        :class="[
          'py-4 px-4 mt-4 d-flex align-center text-body-2 font-weight-light rounded-lg',
        ]"
      >
        <v-icon dense class="mr-2">mdi-information-outline</v-icon>
        {{ option.info }}
      </div>

      <div
        v-if="option.sublabel != void 0"
        class="py-0 px-4 mt-4 text-body-2 rounded-lg"
      >
        {{ option.sublabel }}
      </div>
    </div>
  </div>
</template>
