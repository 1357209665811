<script>
export default {
  props: {
    options: Array,
    value: [String, Array],
    multiple: Boolean,
    dense: Boolean,
    gridGap: {
      type: String,
      default: '1.5rem',
    },
    minHeight: { type: String, default: 'auto' },
  },
}
</script>
<template>
  <!--
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-items: center;
  -->
  <div
    style="
      display: grid;
      justify-content: center;
    "
    :style="{
      'grid-gap': gridGap,
      'grid-template-columns':
        dense === true ? 'repeat(auto-fit, 150px)' : 'repeat(auto-fit, 300px)',
    }"
  >
    <div
      v-for="option in options"
      :key="option.value"
      :class="[
        'pa-3 rounded cursor-pointer hover:secondary',
        {
          'secondary white--text':
            multiple === true
              ? dense === true && (value || []).includes(option.value)
              : value === option.value,
        },
        { 'd-flex align-center justify-center text-center': dense === true },
      ]"
      style="border: 1px solid #dadada; position: relative;"
      :style="{ minHeight }"
      @click="
        $emit(
          'input',
          multiple === true
            ? (value || []).includes(option.value)
              ? (value || []).filter(val => val !== option.value)
              : (value || []).concat(option.value)
            : option.value
        )
        $emit('selected', [
          option.value,
          multiple === true ? !(value || []).includes(option.value) : true,
          // : value !== option.value,
        ])
      "
    >
      <div v-if="multiple === true && dense === false" class="mb-2">
        <v-checkbox
          :input-value="(value || []).includes(option.value)"
          dense
          hide-details
          class="mt-0 pt-0"
          :label="option.label"
        ></v-checkbox>
      </div>
      <div v-else-if="dense === true" style="font-size: 14px;">
        {{ option.label }}
      </div>
      <img
        v-if="option.url"
        :src="option.url"
        alt="Design Thumbnail"
        width="276"
        height="208"
        class="rounded img-fluid"
      />
      <div
        v-if="option.badge != void 0"
        class="pa-3 pb-5 font-weight-semibold text-caption white--text"
        style="position: absolute; top: 8px; left: 8px; font-size: 18px;"
      >
        <div
          class="d-flex px-3 py-1 rounded align-center justify-center"
          style="background: rgba(206, 39, 39, 0.8); border: 1px solid white;"
        >
          {{ option.badge }}
        </div>
      </div>
      <caption
        v-if="option.caption != void 0"
        class="pa-3 pb-5 font-weight-semibold white--text"
        style="position: absolute; width: 100%; bottom: 0; left: 0; right: 0; font-size: 18px;"
      >
        <div
          class="d-flex align-center justify-center"
          style="min-height: 48px; background: linear-gradient(360deg, #222425 0%, rgba(34, 36, 37, 0) 100%);"
        >
          {{ option.caption }}
        </div>
      </caption>
    </div>
  </div>
</template>
