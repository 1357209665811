var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"grid","justify-content":"center"},style:({
    'grid-template-columns': _vm.$vuetify.breakpoint.mdAndUp
      ? ("repeat(" + (_vm.options.length) + ", 1fr)")
      : 'repeat(auto-fit, 300px)',
    'grid-gap': _vm.options.some(
      function (option) { return option['top-label'] ||
        option['top-img'] ||
        option.info ||
        option.sublabel; }
    )
      ? '3rem 1.5rem'
      : '1rem 1.5rem',
  })},_vm._l((_vm.options),function(option){return _c('div',{key:option.value,class:['pa-3 pa-md-3 rounded', { 'cursor-pointer': _vm.outerbox }],style:({ border: _vm.outerbox ? '1px solid #dadada' : 'unset' }),on:{"click":function($event){_vm.outerbox ? _vm.$emit('input', option.value) : void 0}}},[(option['top-label'] != void 0)?_c('div',{staticClass:"mb-4 mb-md-8 text-center text-h6"},[_vm._v(" "+_vm._s(option['top-label'])+" ")]):_vm._e(),(option['top-img'])?_c('img',{staticClass:"mx-auto mb-4 d-block rounded img-fluid",attrs:{"src":option['top-img'],"alt":"Thumbnail","width":"290","height":"111"}}):_vm._e(),_c('div',{class:[
        'px-4 d-flex align-center justify-center font-weight-semibold rounded-lg cursor-pointer hover:secondary hover:white--text text-center',
        !_vm.outerbox ? 'py-4' : 'py-2',
        {
          'text-h6': option['black-label'] === true,
          secondary: _vm.value === option.value,
          'white--text': _vm.value === option.value,
        } ],style:({ border: !_vm.outerbox ? '1px solid #dadada' : 'unset' }),on:{"click":function($event){$event.stopPropagation();return _vm.$emit('input', option.value)}}},[(option['label-icon'])?_c('v-icon',{staticClass:"mr-2",attrs:{"dense":"","color":_vm.value === option.value ? 'white' : void 0}},[_vm._v(" "+_vm._s(option['label-icon'])+" ")]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(option.label)}})],1),(option.info != void 0)?_c('div',{class:[
        'py-4 px-4 mt-4 d-flex align-center text-body-2 font-weight-light rounded-lg' ]},[_c('v-icon',{staticClass:"mr-2",attrs:{"dense":""}},[_vm._v("mdi-information-outline")]),_vm._v(" "+_vm._s(option.info)+" ")],1):_vm._e(),(option.sublabel != void 0)?_c('div',{staticClass:"py-0 px-4 mt-4 text-body-2 rounded-lg"},[_vm._v(" "+_vm._s(option.sublabel)+" ")]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }